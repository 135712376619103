import React, { useState, useEffect } from "react";
import Header from "../../../pages/Sidebar/header";
import Cards from "../../../pages/cards/cards";

import Pantry from "../../../assets/img/profiles/pantry.svg";
import Emergency from "../../../assets/img/profiles/emergency.svg";
import Tea from "../../../assets/img/profiles/tea-cup.svg";
import GreenTea from "../../../assets/img/profiles/green-tea.svg";
import Coffee from "../../../assets/img/profiles/coffee-cup.svg";
import Nachos from "../../../assets/img/profiles/nachos.svg";
import Biscuit from "../../../assets/img/profiles/cookies.svg";
import Water from "../../../assets/img/profiles/water.svg";
import SmileEmoji from "../../../assets/img/profiles/smilie-emoji.gif";
import Back from "../../../assets/img/profiles/arrowleft.svg";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { FaMinus, FaPlus } from "react-icons/fa";
import Fab from "@mui/material/Fab";
import Swal from "sweetalert2";
import axios from "axios";
import closeIcon from "../../../assets/img/profiles/whiteclose.svg";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import Select from "react-select";
const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.success.main,
    background: theme.palette.success,
  },
}));

const AdminPantry = () => {
  const [menu, setMenu] = useState(false);
  const [tea, setTea] = useState(0);
  const [green, setGreen] = useState(0);
  const [amount, setAmount] = useState(0);
  const [coffee, setCoffee] = useState(0);
  const [biscuit, setBiscuit] = useState(0);
  const [status, setStatus] = useState({ label: "", value: "" });
  const [step, setStep] = useState(0);
  const token = sessionStorage.getItem("access_token");
  const [source, setSource] = useState();
  const [list, setList] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [search, setSearch] = useState([]);
  const [singleOrder, setSingleOrder] = useState({});
  const classes = useStyles();

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const steps = ["Processing", "Delivered"];

  const data = [
    {
      id: 1,
      date: "16-10-2023",
      time: "02:30 PM",
      quantity: 1,
      desk_number: 4,
      status: "Processing",
    },
    {
      id: 2,
      date: "16-10-2023",
      time: "02:30 PM",
      quantity: 1,
      desk_number: 4,
      status: "Processing",
    },
    {
      id: 3,
      date: "16-10-2023",
      time: "02:30 PM",
      quantity: 1,
      desk_number: 4,
      status: "Processing",
    },
    {
      id: 4,
      date: "16-10-2023",
      time: "02:30 PM",
      quantity: 1,
      desk_number: 4,
      status: "Processing",
    },
    {
      id: 5,
      date: "16-10-2023",
      time: "02:30 PM",
      quantity: 1,
      desk_number: 4,
      status: "Processing",
    },
    {
      id: 1,
      date: "16-10-2023",
      time: "02:30 PM",
      quantity: 1,
      desk_number: 4,
      status: "Processing",
    },
    {
      id: 2,
      date: "16-10-2023",
      time: "02:30 PM",
      quantity: 1,
      desk_number: 4,
      status: "Processing",
    },
    {
      id: 3,
      date: "16-10-2023",
      time: "02:30 PM",
      quantity: 1,
      desk_number: 4,
      status: "Processing",
    },
    {
      id: 4,
      date: "16-10-2023",
      time: "02:30 PM",
      quantity: 1,
      desk_number: 4,
      status: "Processing",
    },
    {
      id: 5,
      date: "16-10-2023",
      time: "02:30 PM",
      quantity: 1,
      desk_number: 4,
      status: "Processing",
    },
    {
      id: 1,
      date: "16-10-2023",
      time: "02:30 PM",
      quantity: 1,
      desk_number: 4,
      status: "Processing",
    },
    {
      id: 2,
      date: "16-10-2023",
      time: "02:30 PM",
      quantity: 1,
      desk_number: 4,
      status: "Processing",
    },
    {
      id: 3,
      date: "16-10-2023",
      time: "02:30 PM",
      quantity: 1,
      desk_number: 4,
      status: "Processing",
    },
    {
      id: 4,
      date: "16-10-2023",
      time: "02:30 PM",
      quantity: 1,
      desk_number: 4,
      status: "Processing",
    },
    {
      id: 5,
      date: "16-10-2023",
      time: "02:30 PM",
      quantity: 1,
      desk_number: 4,
      status: "Processing",
    },
  ];
  const handleNumberOperation = (e, value, item) => {
    e.preventDefault();
    if (item == "tea") {
      if (value == -1 && tea == 0) {
        setTea(tea);
      } else {
        setTea((prev) => prev + value);
      }
    } else if (item == "coffee") {
      if (value == -1 && coffee == 0) {
        setCoffee(coffee);
      } else {
        setCoffee((prev) => prev + value);
      }
    } else if (item == "biscuit") {
      if (value == -1 && biscuit == 0) {
        setBiscuit(biscuit);
      } else {
        setBiscuit((prev) => prev + value);
      }
    } else if (item == "green") {
      if (value == -1 && green == 0) {
        setGreen(green);
      } else {
        setGreen((prev) => prev + value);
      }
    }
  };
  const handleOrderSubmit = (e) => {
    e.preventDefault();
    setBiscuit(0);
    setTea(0);
    setCoffee(0);
    setGreen(0);
    Swal.fire({
      icon: "success",
      text: "Your Order Placed successfully",
      timer: 1500,
    });
  };

  const handleOrderGet = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/pantryOrder/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setOrderList(res.data);
        setSearch(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleOrderGet();
  }, []);

  const handleStatus = (e, id, status) => {
    e.preventDefault();
    axios
      .patch(`${process.env.REACT_APP_BACKEND_URL}/pantryOrder/`, {
        order_id: id,
        status: status,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          // title: "Oops...",
          text: "Status Updated",
          // timer: 3000,
        });
        handleOrderGet();
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    const newData = search.filter(
      (x) => x.status == (status.value === "" ? x.status : status.value)
    );
    setOrderList(newData);
  }, [status]);

  return (
    <div
      className={`main-wrapper ${menu ? "slide-nav" : ""}`}
      style={{ background: "#262339" }}
    >
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />

      <div
        className="page-wrapper col-md-12"
        // style={{ backgroundColor: changeTheme.background }}
        style={{ marginLeft: "80px" }}
      >
        {/* <img src={happylogo} style={{ height: "20%", width: "100%" }} /> */}

        <div className="row">
          <div className="top-pantry-div">
            <div className="col-md-9">
              <div className="pantry-info-left">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  <div>
                    <h3 className="welcome-bold">Orders</h3>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <label>Status:</label> &nbsp;&nbsp;
                      <Select
                        inputId="single-select-example"
                        className="single-select"
                        classNamePrefix="react-select"
                        isClearable
                        placeholder="status"
                        options={[
                          { label: "Accepted", value: "Accepted" },
                          { label: "Rejected", value: "Rejected" },
                          { label: "Processing", value: "Processing" },
                          { label: "Delivered", value: "Delivered" },
                        ]}
                        value={status}
                        onChange={(e) =>
                          setStatus(e ? e : { label: "", value: "" })
                        }
                      />
                    </div>
                    {/* <button
                      style={{
                        background: "#ED9D44",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        padding: "8px 30px",
                        marginBottom: "15px",
                        fontWeight: "600",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#create_order"
                      href="#"
                    >
                      <i className="fa fa-plus" /> &nbsp; Place New Order
                    </button> */}
                  </div>
                </div>
                <div className="table-container-pantry">
                  <table className="custom-table-pantry">
                    <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Desk Number</th>
                        <th>Employee Name</th>
                        <th>Date</th>
                        <th>Time</th>

                        <th>Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {orderList
                        .sort((a, b) => {
                          return a.order_id - b.order_id;
                        })
                        .map((item) => (
                          <tr key={item.order_id}>
                            <td
                              style={{ color: "blue", cursor: "pointer" }}
                              onClick={() => setSingleOrder(item)}
                            >
                              {item.order_id}
                            </td>
                            <td>{item.unique_id ? item.unique_id : "--"}</td>
                            <td>{item.emp_name}</td>
                            <td>{item.date}</td>
                            <td>{item.time?.slice(0, 8)}</td>
                            {/* <td>{item.quantity}</td> */}

                            {/* <td>{item.status}</td> */}
                            <td style={{ color: "blue" }}>
                              <div className="dropdown action-label text-center">
                                <a
                                  className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                  href="#"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  disabled={
                                    item.status === "Delivered" ? true : false
                                  }
                                  style={{
                                    color:
                                      item.status === "Delivered"
                                        ? "#29CD00"
                                        : item.status === "Processing"
                                        ? "#ED9D44"
                                        : item.status === "Rejected"
                                        ? "#FF0000"
                                        : "#973FCF",
                                    fontWeight: "600",
                                  }}
                                >
                                  {/* <i
                                  className={
                                    item.status === "Serving Notice Period"
                                      ? "fa fa-dot-circle-o text-purple"
                                      : item.status === "Withdrawn"
                                      ? "fa fa-dot-circle-o text-primary"
                                      : item.status === "Active"
                                      ? "fa fa-dot-circle-o text-success"
                                      : "fa fa-dot-circle-o text-danger"
                                  }
                                />{" "} */}
                                  {item.status}
                                </a>
                                <div
                                  className="dropdown-menu dropdown-menu"
                                  style={{ textAlign: "center" }}
                                >
                                  {item.status !== "Rejected" && (
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={(e) =>
                                        handleStatus(
                                          e,
                                          item.order_id,
                                          "Rejected"
                                        )
                                      }
                                      style={{
                                        color: "#FF0000",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {/* <i className="fa fa-dot-circle-o text-danger" />{" "} */}
                                      Reject
                                    </a>
                                  )}
                                  {item.status !== "Processing" && (
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={(e) =>
                                        handleStatus(
                                          e,
                                          item.order_id,
                                          "Processing"
                                        )
                                      }
                                      style={{
                                        color: "#ED9D44",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {/* <i className="fa fa-dot-circle-o text-primary" /> */}
                                      Process
                                    </a>
                                  )}
                                  {item.status !== "Delivered" && (
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={(e) =>
                                        handleStatus(
                                          e,
                                          item.order_id,
                                          "Delivered"
                                        )
                                      }
                                      style={{
                                        color: "#29CD00",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {/* <i className="fa fa-dot-circle-o text-success" /> */}
                                      Delivered
                                    </a>
                                  )}
                                  {item.status !== "Accepted" && (
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={(e) =>
                                        handleStatus(
                                          e,
                                          item.order_id,
                                          "Accepted"
                                        )
                                      }
                                      style={{
                                        color: "#973FCF",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {/* <i className="fa fa-dot-circle-o text-success" /> */}
                                      Accept
                                    </a>
                                  )}
                                </div>
                              </div>
                              {/* <Select
                              value={{ label: item.status, value: item.status }}
                              styles={{ color: "blue" }}
                              options={[
                                { label: "Accepted", value: "Accepted" },
                                { label: "Delivered", value: "Delivered" },
                                { label: "Rejected", value: "Rejected" },
                              ]}
                              // value={status}
                              onChange={(e) => setItem}
                            />{" "} */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
            </div>
            <div className="col-md-3">
              <div>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid #c4c4c4",
                    // padding: "10px",
                    marginTop: "17px",
                  }}
                >
                  <h4>Order Details</h4>
                </div>
                <div className={classes.container}>
                  {/* <Box sx={{ width: "100%", marginTop: "20px" }}>
                    <Stepper activeStep={step} alternativeLabel>
                      {steps.map((label) => (
                        <Step className={classes.container} key={label}>
                          <StepLabel sx={{ color: "#ED9D44" }}>
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box> */}
                </div>
                <div
                  style={{
                    // fontWeight: "600",
                    fontSize: "14px",
                    margin: "30px",
                  }}
                >
                  <p>
                    Employee ID :
                    <span style={{ color: "#3298DB" }}>
                      {singleOrder.empid}
                    </span>
                  </p>
                  <p>
                    Employee Name :
                    <span style={{ color: "#3298DB" }}>
                      {singleOrder.emp_name}
                    </span>
                  </p>
                  <p>
                    Desk Number :
                    <span style={{ color: "#3298DB" }}>
                      {singleOrder.unique_id}
                    </span>
                  </p>
                </div>

                {singleOrder?.items?.map((ele) => (
                  <div style={{ margin: "40px" }}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "30px",
                          justifyContent: "flex-start",
                          margin: "20px",
                        }}
                      >
                        <div>
                          <img
                            src={
                              ele.name === "coffee"
                                ? Coffee
                                : ele.name === "chai_tea"
                                ? Tea
                                : ele.name === "green_tea"
                                ? GreenTea
                                : Water
                            }
                          />
                        </div>
                        <div>
                          <p>
                            Item Type: <b>{ele.name}</b>
                          </p>
                          <p>
                            Quantity: <b>{ele.qt}</b>
                          </p>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          id="create_order"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="pantry-heading">Quick Order</h4>
              </div>
              <div className="modal-body">
                <form>
                  {list === false ? (
                    <>
                      <div className="form-group task-board-color">
                        <div className="menu-row1">
                          <div className="menu-row2">
                            <div>
                              <img src={Tea} />
                              <p className="pantry-item">
                                <b>Tea</b>
                              </p>
                              <div className="amount-toggle">
                                <button
                                  style={{ borderRadius: "15px 0px 0px 15px" }}
                                  className="amount-button"
                                  value={Tea}
                                  onClick={(e) =>
                                    handleNumberOperation(e, -1, "tea")
                                  }
                                >
                                  <FaMinus />
                                </button>
                                <div className="amount-style">{tea}</div>
                                <button
                                  style={{ borderRadius: "0px 15px 15px 0px " }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, 1, "tea")
                                  }
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="menu-row2">
                            <div>
                              <img src={GreenTea} />
                              <p className="pantry-item">
                                <b> Green Tea</b>
                              </p>
                              <div className="amount-toggle">
                                <button
                                  style={{ borderRadius: "15px 0px 0px 15px" }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, -1, "green")
                                  }
                                >
                                  <FaMinus />
                                </button>
                                <div className="amount-style">{green}</div>
                                <button
                                  style={{ borderRadius: "0px 15px 15px 0px " }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, 1, "green")
                                  }
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="menu-row2">
                            <div>
                              <img src={Coffee} />
                              <p className="pantry-item">
                                <b>Coffee</b>
                              </p>
                              <div className="amount-toggle">
                                <button
                                  style={{ borderRadius: "15px 0px 0px 15px" }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, -1, "coffee")
                                  }
                                >
                                  <FaMinus />
                                </button>
                                <div className="amount-style">{coffee}</div>
                                <button
                                  style={{ borderRadius: "0px 15px 15px 0px " }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, 1, "coffee")
                                  }
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="menu-row2">
                            <div>
                              <img src={Biscuit} />
                              <p className="pantry-item">
                                <b> Biscuit</b>
                              </p>
                              <div className="amount-toggle">
                                <button
                                  style={{ borderRadius: "15px 0px 0px 15px" }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, -1, "biscuit")
                                  }
                                >
                                  <FaMinus />
                                </button>
                                <div className="amount-style">{biscuit}</div>
                                <button
                                  style={{ borderRadius: "0px 15px 15px 0px " }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, 1, "biscuit")
                                  }
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* <div className="menu-row2">
                            <div>
                              <img src={Nachos} />
                              <p className="pantry-item">
                                <b> Snacks</b>
                              </p>
                              <div className="amount-toggle">
                                <button
                                  style={{ borderRadius: "15px" }}
                                  className="amount-button"
                                  onClick={() => setList(true)}
                                >
                                  view List
                                </button>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Any instructions?"
                          style={{ maxWidth: "350px", marginLeft: "4%" }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <p style={{ marginLeft: "20px" }}>
                        <img src={Nachos} />{" "}
                        <b>Select your favorite snacks from list</b>
                      </p>
                      <ul
                        style={{
                          listStyleType: "none",
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        <li
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ accentColor: "#ed9d44" }}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Chips</b>{" "}
                          <div
                            className="amount-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexGrow: "1",
                            }}
                          >
                            <button
                              style={{ borderRadius: "15px 0px 0px 15px" }}
                              className="amount-button"
                              // onClick={() => setDecrease()}
                            >
                              <FaMinus />
                            </button>
                            <div className="amount-style">{amount}</div>
                            <button
                              style={{ borderRadius: "0px 15px 15px 0px " }}
                              className="amount-button"
                              // onClick={() => setIncrease()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ accentColor: "#ed9d44" }}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Samosa</b>{" "}
                          <div
                            className="amount-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexGrow: "1",
                            }}
                          >
                            <button
                              style={{ borderRadius: "15px 0px 0px 15px" }}
                              className="amount-button"
                              // onClick={() => setDecrease()}
                            >
                              <FaMinus />
                            </button>
                            <div className="amount-style">{amount}</div>
                            <button
                              style={{ borderRadius: "0px 15px 15px 0px " }}
                              className="amount-button"
                              // onClick={() => setIncrease()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ accentColor: "#ed9d44" }}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Vada Pav</b>{" "}
                          <div
                            className="amount-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexGrow: "1",
                            }}
                          >
                            <button
                              style={{ borderRadius: "15px 0px 0px 15px" }}
                              className="amount-button"
                              // onClick={() => setDecrease()}
                            >
                              <FaMinus />
                            </button>
                            <div className="amount-style">{amount}</div>
                            <button
                              style={{ borderRadius: "0px 15px 15px 0px " }}
                              className="amount-button"
                              // onClick={() => setIncrease()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ accentColor: "#ed9d44" }}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Ice Cream</b>{" "}
                          <div
                            className="amount-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexGrow: "1",
                            }}
                          >
                            <button
                              style={{ borderRadius: "15px 0px 0px 15px" }}
                              className="amount-button"
                              // onClick={() => setDecrease()}
                            >
                              <FaMinus />
                            </button>
                            <div className="amount-style">{amount}</div>
                            <button
                              style={{ borderRadius: "0px 15px 15px 0px " }}
                              className="amount-button"
                              // onClick={() => setIncrease()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ accentColor: "#ed9d44", color: "white" }}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Bhel Puri</b>{" "}
                          <div
                            className="amount-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexGrow: "1",
                            }}
                          >
                            <button
                              style={{ borderRadius: "15px 0px 0px 15px" }}
                              className="amount-button"
                              // onClick={() => setDecrease()}
                            >
                              <FaMinus />
                            </button>
                            <div className="amount-style">{amount}</div>
                            <button
                              style={{ borderRadius: "0px 15px 15px 0px " }}
                              className="amount-button"
                              // onClick={() => setIncrease()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </li>
                      </ul>
                    </>
                  )}
                  <div className="m-t-20 text-center">
                    <button
                      className="btn"
                      data-bs-dismiss="modal"
                      style={{ background: "#ed9d44", color: "white" }}
                      onClick={(e) => handleOrderSubmit(e)}
                    >
                      Order
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPantry;
