import React, { useState, useEffect } from "react";
import Header from "../../../pages/Sidebar/header";
import Cards from "../../../pages/cards/cards";

import Pantry from "../../../assets/img/profiles/pantry.svg";
import Emergency from "../../../assets/img/profiles/emergency.svg";
import Tea from "../../../assets/img/profiles/tea-cup.svg";
import GreenTea from "../../../assets/img/profiles/green-tea.svg";
import Coffee from "../../../assets/img/profiles/coffee-cup.svg";
import Nachos from "../../../assets/img/profiles/nachos.svg";
import Biscuit from "../../../assets/img/profiles/cookies.svg";
import Water from "../../../assets/img/profiles/water.svg";
import SmileEmoji from "../../../assets/img/profiles/smilie-emoji.gif";
import Back from "../../../assets/img/profiles/arrowleft.svg";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { FaMinus, FaPlus } from "react-icons/fa";
import Fab from "@mui/material/Fab";
import Swal from "sweetalert2";
import axios from "axios";
import closeIcon from "../../../assets/img/profiles/whiteclose.svg";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.success.main,
    background: theme.palette.success,
  },
}));

const PantryOrder = () => {
  const [menu, setMenu] = useState(false);
  const [tea, setTea] = useState(0);
  const [green, setGreen] = useState(0);
  const [amount, setAmount] = useState(0);
  const [coffee, setCoffee] = useState(0);
  const [biscuit, setBiscuit] = useState(0);
  const [mood, setMood] = useState("");
  const [step, setStep] = useState(0);
  const token = sessionStorage.getItem("access_token");
  const [orderDetail, setOrderDetail] = useState({});
  const [list, setList] = useState(false);
  const [detail, setDetail] = useState({
    unique_id: "",
    instruction: "",
  });

  const classes = useStyles();

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const steps = ["Processing", "Delivered"];

  const [data, setData] = useState([]);
  const handleNumberOperation = (e, value, item) => {
    e.preventDefault();
    if (item == "tea") {
      if (value == -1 && tea == 0) {
        setTea(tea);
      } else {
        setTea((prev) => prev + value);
      }
    } else if (item == "coffee") {
      if (value == -1 && coffee == 0) {
        setCoffee(coffee);
      } else {
        setCoffee((prev) => prev + value);
      }
    } else if (item == "biscuit") {
      if (value == -1 && biscuit == 0) {
        setBiscuit(biscuit);
      } else {
        setBiscuit((prev) => prev + value);
      }
    } else if (item == "green") {
      if (value == -1 && green == 0) {
        setGreen(green);
      } else {
        setGreen((prev) => prev + value);
      }
    }
  };
  const handleGetOrder = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/pantryOrder/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
      .then((res) => {
        setData(res.data);
        setOrderDetail(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOrderSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/pantryOrder/`, {
        ["Chai Tea"]: tea,
        Coffee: coffee,
        Water: 0,
        ["Green Tea"]: green,
        instructions: detail.instruction,
        sender_unique_identity: detail.unique_id
          ? detail.unique_id
          : sessionStorage.getItem("name"),
        sender_id: sessionStorage.getItem("emp_id"),
      })
      .then((res) => {
        setBiscuit(0);
        setTea(0);
        setCoffee(0);
        setGreen(0);
        setDetail({ unique_id: "", instruction: "" });
        handleGetOrder();
        Swal.fire({
          icon: "success",
          text: "Your Order Placed successfully",
          timer: 1500,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetOrder();
  }, []);

  return (
    <div
      className={`main-wrapper ${menu ? "slide-nav" : ""}`}
      style={{ background: "#262339" }}
    >
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <div
        className="happyzone-container"
        // style={{ backgroundColor: changeTheme.background }}
      >
        {/* <img src={happylogo} style={{ height: "20%", width: "100%" }} /> */}

        <div className="row">
          <div className="top-pantry-div">
            <div className="col-md-8">
              <div className="pantry-info-left">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  <div>
                    <Link to="/happyzone-two" style={{ color: "white" }}>
                      <button className="pantry-back-happyzone">
                        <span>
                          <img src={Back} />
                        </span>{" "}
                      </button>
                    </Link>
                    <h3 className="welcome-bold">Orders</h3>
                  </div>
                  <div>
                    <button
                      style={{
                        background: "#ED9D44",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        padding: "8px 30px",
                        marginBottom: "15px",
                        fontWeight: "600",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#create_order"
                      href="#"
                    >
                      <i className="fa fa-plus" /> &nbsp; Place New Order
                    </button>
                  </div>
                </div>
                <div className="table-container-pantry">
                  <table className="custom-table-pantry">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Time</th>

                        <th>Desk Number</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data.map((item) => (
                        <tr key={item.order_id}>
                          <td
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => setOrderDetail(item)}
                          >
                            {item.order_id}
                          </td>
                          <td>{item.date}</td>
                          <td>{item.time?.slice(0, 8)}</td>

                          <td>{item.unique_id ? item.unique_id : "--"}</td>
                          <td
                            style={{
                              color:
                                item.status === "Delivered"
                                  ? "#29CD00"
                                  : item.status === "Processing"
                                  ? "#ED9D44"
                                  : item.status === "Rejected"
                                  ? "#FF0000"
                                  : "#973FCF",
                              fontWeight: "600",
                            }}
                          >
                            {item.status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
            </div>
            <div className="col-md-4">
              <div>
                <div
                  style={{
                    textAlign: "center",
                    borderBottom: "2px solid #c4c4c4",
                    padding: "10px",
                    marginTop: "17px",
                  }}
                >
                  <h4>Order Details</h4>
                </div>
                {/* <div className={classes.container}>
                  <Box sx={{ width: "100%", marginTop: "20px" }}>
                    <Stepper activeStep={step} alternativeLabel>
                      {steps.map((label) => (
                        <Step className={classes.container} key={label}>
                          <StepLabel sx={{ color: "#ED9D44" }}>
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </div> */}
                <div style={{ margin: "30px" }}>
                  <p style={{ fontWeight: "500", fontSize: "16px" }}>
                    Desk Number :<b>{orderDetail?.unique_id}</b>
                  </p>
                  <div style={{ marginTop: "35px" }}>
                    {orderDetail?.items?.map((ele) => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "30px",
                            marginTop: "30px",
                            justifyContent: "flex-start",
                            margin: "20px",
                          }}
                        >
                          <div>
                            <img
                              src={
                                ele.name === "coffee"
                                  ? Coffee
                                  : ele.name === "chai_tea"
                                  ? Tea
                                  : ele.name === "green_tea"
                                  ? GreenTea
                                  : Water
                              }
                            />
                          </div>
                          <div>
                            <p>
                              Item Type: <b>{ele.name}</b>
                            </p>
                            <p>
                              Quantity: <b>{ele.qt}</b>
                            </p>
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}
                    {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "30px",
                        justifyContent: "flex-start",
                        margin: "20px",
                      }}
                    >
                      <div>
                        <img src={Coffee} />
                      </div>
                      <div>
                        <p>
                          Item Type: <b>Coffee</b>
                        </p>
                        <p>
                          Quantity: <b>2</b>
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "30px",
                        justifyContent: "flex-start",
                        margin: "20px",
                      }}
                    >
                      <div>
                        <img src={GreenTea} />
                      </div>
                      <div>
                        <p>
                          Item Type: <b>GreenTea</b>
                        </p>
                        <p>
                          Quantity: <b>2</b>
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "30px",
                        justifyContent: "flex-start",
                        margin: "20px",
                      }}
                    >
                      <div>
                        <img src={Nachos} />
                      </div>
                      <div>
                        <p>
                          Item Type: <b>Nachos</b>
                        </p>
                        <p>
                          Quantity: <b>4</b>
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "30px",
                        justifyContent: "flex-start",
                        margin: "20px",
                      }}
                    >
                      <div>
                        <img src={Tea} />
                      </div>
                      <div>
                        <p>
                          Item Type: <b>Tea</b>
                        </p>
                        <p>
                          Quantity: <b>4</b>
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "30px",
                        justifyContent: "flex-start",
                        margin: "20px",
                      }}
                    >
                      <div>
                        <img src={Biscuit} />
                      </div>
                      <div>
                        <p>
                          Item Type: <b>Biscuit</b>
                        </p>
                        <p>
                          Quantity: <b>2</b>
                        </p>
                      </div>
                    </div>
                    <hr /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="create_order"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="pantry-heading">Quick Order</h4>
              </div>
              <div className="modal-body">
                <form>
                  {list === false ? (
                    <>
                      <div className="form-group task-board-color">
                        <div className="menu-row1">
                          <div className="menu-row2">
                            <div>
                              <img src={Tea} />
                              <p className="pantry-item">
                                <b>Tea</b>
                              </p>
                              <div className="amount-toggle">
                                <button
                                  style={{ borderRadius: "15px 0px 0px 15px" }}
                                  className="amount-button"
                                  value={Tea}
                                  onClick={(e) =>
                                    handleNumberOperation(e, -1, "tea")
                                  }
                                >
                                  <FaMinus />
                                </button>
                                <div className="amount-style">{tea}</div>
                                <button
                                  style={{ borderRadius: "0px 15px 15px 0px " }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, 1, "tea")
                                  }
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="menu-row2">
                            <div>
                              <img src={GreenTea} />
                              <p className="pantry-item">
                                <b> Green Tea</b>
                              </p>
                              <div className="amount-toggle">
                                <button
                                  style={{ borderRadius: "15px 0px 0px 15px" }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, -1, "green")
                                  }
                                >
                                  <FaMinus />
                                </button>
                                <div className="amount-style">{green}</div>
                                <button
                                  style={{ borderRadius: "0px 15px 15px 0px " }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, 1, "green")
                                  }
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="menu-row2">
                            <div>
                              <img src={Coffee} />
                              <p className="pantry-item">
                                <b>Coffee</b>
                              </p>
                              <div className="amount-toggle">
                                <button
                                  style={{ borderRadius: "15px 0px 0px 15px" }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, -1, "coffee")
                                  }
                                >
                                  <FaMinus />
                                </button>
                                <div className="amount-style">{coffee}</div>
                                <button
                                  style={{ borderRadius: "0px 15px 15px 0px " }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, 1, "coffee")
                                  }
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="menu-row2">
                            <div>
                              <img src={Biscuit} />
                              <p className="pantry-item">
                                <b> Biscuit</b>
                              </p>
                              <div className="amount-toggle">
                                <button
                                  style={{ borderRadius: "15px 0px 0px 15px" }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, -1, "biscuit")
                                  }
                                >
                                  <FaMinus />
                                </button>
                                <div className="amount-style">{biscuit}</div>
                                <button
                                  style={{ borderRadius: "0px 15px 15px 0px " }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, 1, "biscuit")
                                  }
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* <div className="menu-row2">
                            <div>
                              <img src={Nachos} />
                              <p className="pantry-item">
                                <b> Snacks</b>
                              </p>
                              <div className="amount-toggle">
                                <button
                                  style={{ borderRadius: "15px" }}
                                  className="amount-button"
                                  onClick={() => setList(true)}
                                >
                                  view List
                                </button>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Desk Number"
                          style={{ maxWidth: "150px", marginLeft: "4%" }}
                          value={detail.unique_id}
                          onChange={(e) =>
                            setDetail({
                              ...detail,
                              unique_id: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Any instructions?"
                          style={{ maxWidth: "350px", marginLeft: "4%" }}
                          value={detail.instruction}
                          onChange={(e) =>
                            setDetail({
                              ...detail,
                              instruction: e.target.value,
                            })
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <p style={{ marginLeft: "20px" }}>
                        <img src={Nachos} />{" "}
                        <b>Select your favorite snacks from list</b>
                      </p>
                      <ul
                        style={{
                          listStyleType: "none",
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        <li
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ accentColor: "#ed9d44" }}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Chips</b>{" "}
                          <div
                            className="amount-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexGrow: "1",
                            }}
                          >
                            <button
                              style={{ borderRadius: "15px 0px 0px 15px" }}
                              className="amount-button"
                              // onClick={() => setDecrease()}
                            >
                              <FaMinus />
                            </button>
                            <div className="amount-style">{amount}</div>
                            <button
                              style={{ borderRadius: "0px 15px 15px 0px " }}
                              className="amount-button"
                              // onClick={() => setIncrease()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ accentColor: "#ed9d44" }}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Samosa</b>{" "}
                          <div
                            className="amount-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexGrow: "1",
                            }}
                          >
                            <button
                              style={{ borderRadius: "15px 0px 0px 15px" }}
                              className="amount-button"
                              // onClick={() => setDecrease()}
                            >
                              <FaMinus />
                            </button>
                            <div className="amount-style">{amount}</div>
                            <button
                              style={{ borderRadius: "0px 15px 15px 0px " }}
                              className="amount-button"
                              // onClick={() => setIncrease()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ accentColor: "#ed9d44" }}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Vada Pav</b>{" "}
                          <div
                            className="amount-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexGrow: "1",
                            }}
                          >
                            <button
                              style={{ borderRadius: "15px 0px 0px 15px" }}
                              className="amount-button"
                              // onClick={() => setDecrease()}
                            >
                              <FaMinus />
                            </button>
                            <div className="amount-style">{amount}</div>
                            <button
                              style={{ borderRadius: "0px 15px 15px 0px " }}
                              className="amount-button"
                              // onClick={() => setIncrease()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ accentColor: "#ed9d44" }}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Ice Cream</b>{" "}
                          <div
                            className="amount-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexGrow: "1",
                            }}
                          >
                            <button
                              style={{ borderRadius: "15px 0px 0px 15px" }}
                              className="amount-button"
                              // onClick={() => setDecrease()}
                            >
                              <FaMinus />
                            </button>
                            <div className="amount-style">{amount}</div>
                            <button
                              style={{ borderRadius: "0px 15px 15px 0px " }}
                              className="amount-button"
                              // onClick={() => setIncrease()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ accentColor: "#ed9d44", color: "white" }}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Bhel Puri</b>{" "}
                          <div
                            className="amount-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexGrow: "1",
                            }}
                          >
                            <button
                              style={{ borderRadius: "15px 0px 0px 15px" }}
                              className="amount-button"
                              // onClick={() => setDecrease()}
                            >
                              <FaMinus />
                            </button>
                            <div className="amount-style">{amount}</div>
                            <button
                              style={{ borderRadius: "0px 15px 15px 0px " }}
                              className="amount-button"
                              // onClick={() => setIncrease()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </li>
                      </ul>
                    </>
                  )}
                  <div className="m-t-20 text-center">
                    <button
                      className="btn"
                      data-bs-dismiss="modal"
                      style={{ background: "#ed9d44", color: "white" }}
                      onClick={(e) => handleOrderSubmit(e)}
                    >
                      Order
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PantryOrder;
