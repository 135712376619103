import React, { useState, useRef, useCallback } from "react";
// import { Table } from "antd";
import { Table } from "ant-table-extensions";
import { Tooltip as TooltipOne } from "antd";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import Select from "react-select";
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import FilterOff from "../../../assets/img/profiles/filter-off-fill1.svg";
import { ArrowBack } from "@mui/icons-material";
import { appendErrors } from "react-hook-form";
import { FiUpload, FiDownload } from "react-icons/fi";
import Lozenge from "@atlaskit/lozenge";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { UploadFile } from "@mui/icons-material";
import { css, jsx } from "@emotion/react";
import attendanceSheet from "../../../assets/css/attendance.xlsx";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import moment from "moment";
import tableExport from "antd-table-export";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import { Menu, Box, Modal, useMediaQuery, TextField } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import * as XLSX from "xlsx";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
  DirectionsService,
} from "@react-google-maps/api";
import Map from "../../../components/Map/Map";

const containerStyle = {
  width: "100%",
  height: "80vh",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "150px",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const AdminAttendance = () => {
  const [open, setOpen] = useState(false);
  // const [open2, setOpen2] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [name1, setName1] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [designation, setDesignation] = useState("");
  const [location, setLocation] = useState("");
  const [department1, setDepartment1] = useState("");
  const [status1, setStatus1] = useState("");
  const [empId, setEmpId] = useState("");
  const [showStatus, setShowStatus] = useState(true);
  const [menu, setMenu] = useState(false);
  const role = JSON.parse(sessionStorage.getItem("role"));
  const [code, setCode] = useState("d");
  const [viewLeaveDetails, setViewLeaveDetails] = useState({});
  const [managerList, setManagerList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currDisplayToggle, setCurrDisplayToggle] = useState("");
  const [filter, setFilter] = useState([]);
  const matchsmexact = useMediaQuery("(max-width:630px)");
  const [bioExcel, setBioExcel] = useState({});
  const [getA, setGetA] = useState("");
  const open1 = Boolean(anchorEl);
  const [month, setMonth] = useState("");
  const [viewTracking, setViewTracking] = useState({
    empid: "",
    date_from: moment().format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),
  });
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const center = {
    lat: 19.11356,
    lng: 72.84703,
  };
  const points = [
    {
      lat: 19.12356,
      lng: 72.84703,
    },
    {
      lat: 19.11356,
      lng: 72.84703,
    },
    {
      lat: 19.13356,
      lng: 72.85703,
    },
    {
      lat: 19.13356,
      lng: 72.84703,
    },
  ];

  // const onDirectionsFetched = (status, response) => {
  //   if (status === "OK") {
  //     setDirections(response);
  //   } else {
  //     console.error("Error fetching directions", status);
  //   }
  // };
  // const calculateDirections = async () => {
  //   if (isLoaded && points.length > 1) {
  //     const directionService = new window.google.maps.DirectionsService();
  //     const waypoints = points.map((point) => ({
  //       location: { lat: point.lat, lng: point.lng },
  //     }));

  //     const request = {
  //       origin: waypoints[0].location,
  //       destination: waypoints[waypoints.length - 1].location,
  //       waypoints: waypoints.slice[(1, -1)],
  //       travelMode: window.google.maps.TravelMode.WALKING,
  //     };

  //     const res = await directionService.route(request);

  //     if (res.status === "OK") {
  //       console.log("called me yrr");
  //       setDirections(res);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   calculateDirections();
  // }, [isLoaded]);

  // const onLoad = useCallback(function callback(map) {
  //   // This is just an example of getting and using the map instance!!! don't just blindly copy!
  //   const bounds = new window.google.maps.LatLngBounds(center);
  //   map.fitBounds(bounds);

  //   setMap(map);
  // }, []);
  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null);

  // }, []);

  /* filter*/
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const hiddenFileInput = useRef(null);
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    console.log(true);
    setFilteredInfo({});
    setSortedInfo({});
  };

  const rolePermissions = JSON.parse(sessionStorage.getItem("permissions"));

  const [data, setData] = useState([]);
  const [taggingData, setTaggingData] = useState([]);
  const [monthValue, setMonthValue] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );
  const [timePeriod, setTimePeriod] = useState("today");
  const [declineReason, setDeclineReason] = useState("");
  const [applyLeave, setApplyLeave] = useState("");
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [today, setToday] = useState([]);
  const token = sessionStorage.getItem("access_token");

  const open2 = Boolean(anchorEl2);
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClick2 = (event, text) => {
    setAnchorEl2(event.currentTarget);
    setViewTracking({ ...viewTracking, empid: text.empid, name: text.name });
    handleViewTracking(text.empid);

    // if (isLoaded) {
    //   calculateDirections();
    // }
  };
  const handleViewTracking = (id) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/geo_hit/`, {
        params: {
          empid: id,
          date_from: viewTracking.date_from,
          date_to: viewTracking.date_to,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            sno: index + 1,
          }))
        );

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleViewTrackingOne = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/geo_hit/`, {
        params: {
          empid: viewTracking.empid,
          date_from: viewTracking.date_from,
          date_to: viewTracking.date_to,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            sno: index + 1,
          }))
        );

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleViewTagging = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/clock_lat_long_data/`, {
        params: {
          empid: viewTracking.empid,
          from_date: viewTracking.date_from,
          to_date: viewTracking.date_to,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTaggingData(
          res.data.map((ele, index) => ({
            ...ele,
            sno: index + 1,
          }))
        );

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const uploadAttendance = () => {
    let formData = new FormData();
    formData.append("file", bioExcel);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/bulkAttendance_nym/`,
        {
          // admid: JSON.parse(sessionStorage.getItem("admid")),
          file: formData,
        }
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Biometric Attendance uploaded successfully",
        });
        switchDisplay(applyLeave);
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const handleDownloadCsv = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/attendance_download/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = res.data;
        link.setAttribute("download", `template.csv`);
        link.setAttribute("target", `_blank`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/list_admins/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setManagerList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const switchToggel = (period, cod, e) => {
    setIsLoading(true);
    if (period === "month") {
      setShowStatus(false);
    } else if (period === "week") {
      setShowStatus(false);
    } else {
      setShowStatus(true);
    }
    setTimePeriod(period);
    setCode(cod);
    if (period === "month") {
      setMonthValue(e.target.value);
    }
  };

  const switchDisplay = (mode) => {
    setApplyLeave(mode);
    setCurrDisplayToggle(mode);
    setIsLoading(true);
    if (mode === "wfh") {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/mas_wfh/`, {
          params: {
            admid: sessionStorage.getItem("admid"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setData(
            res.data.map((ele, index) => ({
              ...ele,
              sno: index + 1,
              from_date: ele.from_date
                .substr(0, 10)
                .split("-")
                .reverse()
                .join("-"),
              to_date: ele.to_date.substr(0, 10).split("-").reverse().join("-"),
            }))
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (mode === "halfday") {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/mas_halfday/`, {
          params: {
            admid: sessionStorage.getItem("admid"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setData(
            res.data.map((ele, index) => ({
              ...ele,
              sno: index + 1,
              from_date: ele.on_date
                .substr(0, 10)
                .split("-")
                .reverse()
                .join("-"),
            }))
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (mode === "compoff") {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/mas_camp_off/`, {
          params: {
            admid: sessionStorage.getItem("admid"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setData(
            res.data.map((ele, index) => ({
              ...ele,
              sno: index + 1,
              from_date: ele.from_date
                .substr(0, 10)
                .split("-")
                .reverse()
                .join("-"),
              to_date: ele.to_date.substr(0, 10).split("-").reverse().join("-"),
            }))
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (mode === "regular") {
      //setMonthValue(e.target.value)
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/attendance/`, {
          params: {
            admid: sessionStorage.getItem("admid"),
            year: monthValue.split("-")[0],
            month: monthValue.split("-")[1],
            toggle: "n",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setData(
            res.data.map((ele, index) => ({
              ...ele,
              sno: index + 1,
              key: ele.approval_id,
              date: ele.date?.split("-").reverse().join("-"),
              from_date: ele.date?.split("-").reverse().join("-"),
            }))
          );
          setIsLoading(false);
          setSelectedRowKeys([]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (currDisplayToggle !== "") {
      switchDisplay(currDisplayToggle);
    }
  }, [monthValue]);

  const handleApproveLeave = (e, value) => {
    e.preventDefault();
    if (!declineReason && value) {
      Swal.fire({
        icon: "error",
        text: "Enter the reason!",
      });
    } else {
      if (currDisplayToggle === "wfh") {
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/approve/`,
            {
              admid: JSON.parse(sessionStorage.getItem("admid")),
              approval_id: viewLeaveDetails.approval_id,
              decline: value,
              reason: declineReason,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            Swal.fire({
              icon: res.data.status === false ? "error" : "success",
              // text: res.data.status === true ? "Document Verified" : "",

              // icon:
              //   res.data.error === "Cannot approved by same person"
              //     ? "error"
              //     : "success",
              text:
                res.data.error === "Already Approved"
                  ? "Request is Already Approved"
                  : res.data.error === "Cannot approved by same person"
                  ? "You have no authority to approve or decline request"
                  : res.data.error ===
                    "Cannot approved by someone of lower hierchy"
                  ? "You have no authority to approve or decline request"
                  : res.data.error ===
                    "Cannot approved by someone of different departmenr"
                  ? "You have no authority to approve or decline request"
                  : res.data.status === true
                  ? "Work From Home Approved Successfully"
                  : "Work From Home Rejected",
            });
            switchDisplay(applyLeave);
          })
          .catch((err) => {
            if (err?.response?.status === 400) {
              Swal.fire({
                icon: "error",
                text: `${err?.response?.data?.error}!`,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong",
              });
            }
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/approve/`,
            {
              admid: JSON.parse(sessionStorage.getItem("admid")),
              approval_id: viewLeaveDetails.approval_id,
              decline: value,
              reason: declineReason,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            Swal.fire({
              icon: res.data.status === false ? "error" : "success",
              // text: res.data.status === true ? "Document Verified" : "",

              // icon:
              //   res.data.error === "Cannot approved by same person"
              //     ? "error"
              //     : "success",
              text:
                res.data.error === "Already Approved"
                  ? "Request is Already Approved"
                  : res.data.error === "Cannot approved by same person"
                  ? "You have no authority to approve or decline request"
                  : res.data.error ===
                    "Cannot approved by someone of lower hierchy"
                  ? "You have no authority to approve or decline request"
                  : res.data.error ===
                    "Cannot approved by someone of different departmenr"
                  ? "You have no authority to approve or decline request"
                  : res.data.status === true
                  ? "Request Approved Successfully"
                  : "Request Rejected",
            });
            switchDisplay(applyLeave);
            setSelectedRowKeys([]);
          })
          .catch((err) => {
            if (err?.response?.status === 400) {
              Swal.fire({
                icon: "error",
                text: `${err?.response?.data?.error}!`,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong",
              });
            }
          });
      }
    }
  };

  const handleBulkAction = (e, value) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/approve/`,
        {
          admid: JSON.parse(sessionStorage.getItem("admid")),
          approval_id: selectedRowKeys,
          decline: value,
          // reason: declineReason,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: res.data.status === false ? "error" : "success",
          // text: res.data.status === true ? "Document Verified" : "",

          // icon:
          //   res.data.error === "Cannot approved by same person"
          //     ? "error"
          //     : "success",
          text:
            res.data.error === "Already Approved"
              ? "Request is Already Approved"
              : res.data.error === "Cannot approved by same person"
              ? "You have no authority to approve or decline request"
              : res.data.error === "Cannot approved by someone of lower hierchy"
              ? "You have no authority to approve or decline request"
              : res.data.error ===
                "Cannot approved by someone of different departmenr"
              ? "You have no authority to approve or decline request"
              : res.data.status === true
              ? "Request Approved Successfully"
              : "Request Rejected",
        });
        switchDisplay(applyLeave);
        setSelectedRowKeys([]);
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/attendance/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
          // year: monthValue.split("-")[0],
          // month: monthValue.split("-")[1],
          toggle:
            moment(state[0].startDate).format("YYYY-MM-DD") ===
            moment(state[0].endDate).format("YYYY-MM-DD")
              ? 0
              : "ym",
          range_from: moment(state[0].startDate).format("YYYY-MM-DD"),
          range_to: moment(state[0].endDate).format("YYYY-MM-DD"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setToday(
          res.data.map((ele, index) => ({
            ...ele,
            sno: index + 1,
            //punchin:ele.punchin.substr(11, 8),
            //punchout:ele.punchout.substr(11, 8),
          }))
        );
        setFilter(
          res.data.map((ele) => ({
            text: ele.name,
            value: ele.name,
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [code, state]);

  // const handleMonthlyReport = () => {
  //   axios
  //     .get(`${process.env.REACT_APP_BACKEND_URL}/rrwl_attendance_report/`, {
  //       params: {
  //         coregid: sessionStorage.getItem("coid"),
  //         year: month.split("-")[0],
  //         month: month.split("-")[1],
  //       },
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {

  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleMonthlyReport = async () => {
    let timerInterval;
    Swal.fire({
      title: "Attendance Report Downloading !",
      html: "Your report is being generated. Please wait a moment...",
      // timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        // const timer = Swal.getPopup().querySelector("b");
        // timerInterval = setInterval(() => {
        //   timer.textContent = `${Swal.getTimerLeft()}`;
        // }, 100);
      },
    });
    try {
      const data = {
        coregid: sessionStorage.getItem("coid"),
        year: month.split("-")[0],
        month: month.split("-")[1],
      };

      const response = await fetch(
        sessionStorage.getItem("coid") === `RAWJ225129`
          ? `${process.env.REACT_APP_BACKEND_URL}/rawji_attendance_reportv1/`
          : sessionStorage.getItem("coid") === `OASI370734`
          ? `${process.env.REACT_APP_BACKEND_URL}/Oasis_attendance_reportv1/`
          : sessionStorage.getItem("coid") === `Magn244590`
          ? `${process.env.REACT_APP_BACKEND_URL}/magnum_attendance_reportv1/`
          : `${process.env.REACT_APP_BACKEND_URL}/rrwl_attendance_report/`,
        {
          method: "POST", // Use POST method to send data
          headers: {
            "Content-Type": "application/json", // Set the content type to JSON
          },
          body: JSON.stringify(data), // Convert payload to JSON and send it in the request body
        }
      );

      if (response.ok) {
        // Handle the file download
        console.log(response);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${month}_attendance_report.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        Swal.fire({
          icon: "success",
          text: "Report generated successfully",
          timer: 2000,
        });
        setMonth("");
      } else {
        console.error("Failed to generate report");
        Swal.fire({
          icon: "error",
          //title: "Oops...",
          text: "Something went wrong",
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      Swal.fire({
        icon: "error",
        //title: "Oops...",
        text: "Something went wrong",
        timer: 2000,
      });
    }
  };

  // const handleGetA = async (records) => {
  //   const apiKey = "AIzaSyC-BtiuD2tU8x4DsHhWhsfhrWQDem2Ewz4";
  //   const response = await axios.get(
  //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${records.lat},${records.lng}&key=AIzaSyC-BtiuD2tU8x4DsHhWhsfhrWQDem2Ewz4`
  //   ).then((response) => {
  //     setGetA(response.data.results[1].formatted_address)
  //     return response;
  //   }).then((response) => {
  //       axios.patch(
  //       `${process.env.REACT_APP_BACKEND_URL}/geo_hit/`,
  //       {
  //         id: records.id,
  //         location: response.data.results[1].formatted_address,
  //       })

  //   })
  // };

  const handleGetA = async (records) => {
    try {
      const apiKey = "AIzaSyC-BtiuD2tU8x4DsHhWhsfhrWQDem2Ewz4";
      // Fetch geocode data from Google Maps API
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${records.lat},${records.lng}&key=${apiKey}`
      );

      // Check if response is valid and contains the expected data
      const address = response?.data?.results?.[1]?.formatted_address;

      if (address) {
        // Update state with the formatted address
        setGetA(address);

        // Send PATCH request to update the backend with the location
        await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/geo_hit/`, {
          id: records.id,
          location: address,
        });
        handleViewTrackingOne();
      } else {
        console.error("No address found in the response.");
      }
    } catch (error) {
      console.error("Error fetching address or updating backend:", error);
    }
  };

  const work_location = [
    { label: "Office", value: "Office" },
    { label: "Outdoor", value: "Outdoor" },
    { label: "Work From Home", value: "Work From Home" },
  ];
  const work_mood = [
    { label: "Sad", value: "Sad" },
    { label: "Down", value: "Down" },
    { label: "Indifferent", value: "Indifferent" },
    { label: "Curious", value: "Curious" },
    { label: "Happy", value: "Happy" },
    { label: "Cheerful", value: "Cheerful" },
  ];

  const column3 = [
    {
      title: "Employee name",
      dataIndex: "name",
      // render: (text, record) => (
      //   <h2 className="table-avatar">
      //     <Link to="/app/profile/employee-profile" className="avatar">
      //       <img alt="" src={record.image} />
      //     </Link>
      //     <Link to="/app/profile/employee-profile">
      //       {text} <span>{record.role}</span>
      //     </Link>
      //   </h2>
      // ),
      //sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "From",
      dataIndex: "from_date",
      //sorter: (a, b) => a.from.length - b.from.length,
    },
    {
      title: "To",
      dataIndex: "to_date",
      render: (text, record) => (
        <>
          {!text || text === ("<str>" || null || undefined || "") ? "-" : text}
        </>
      ),
      //sorter: (a, b) => a.to.length - b.to.length,
    },

    // {
    //   title: "No Of Days",
    //   dataIndex: "noofdays",
    //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    // },

    {
      title: "Reason",
      dataIndex: "reason",
      //sorter: (a, b) => a.reason.length - b.reason.length,
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded"
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ textTransform: "capitalize" }}
          >
            <i
              className={
                text === "Declined"
                  ? "fa fa-dot-circle-o text-danger"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-purple"
              }
            />{" "}
            {text}
          </a>
          {/* <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-purple" /> New
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-info" /> Pending
            </a>
            <a
              className="dropdown-item"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#approve_leave"
            >
              <i className="fa fa-dot-circle-o text-success" /> Approved
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-danger" /> Declined
            </a>
          </div> */}
        </div>
      ),
      //sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <button
          style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
          data-bs-toggle="modal"
          data-bs-target="#Leave_info_modal"
          className=" btn-primary submit-btn1"
          onClick={(e) => setViewLeaveDetails(text)}
          disabled={
            currDisplayToggle === "wfh"
              ? rolePermissions["Work From Home"] === "Edit/Create/Approve"
                ? false
                : true
              : rolePermissions["comp off"] === "Edit/Create/Approve"
              ? false
              : true
          }
        >
          View
        </button>
      ),
    },
  ];
  const column4 = [
    {
      title: "Employee name",
      dataIndex: "name",
      // render: (text, record) => (
      //   <h2 className="table-avatar">
      //     <Link to="/app/profile/employee-profile" className="avatar">
      //       <img alt="" src={record.image} />
      //     </Link>
      //     <Link to="/app/profile/employee-profile">
      //       {text} <span>{record.role}</span>
      //     </Link>
      //   </h2>
      // ),
      //sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "From",
      dataIndex: "from_date",
      //sorter: (a, b) => a.from.length - b.from.length,
    },
    // {
    //   title: "To",
    //   dataIndex: "to_date",
    //   show: false,
    //   render: (text, record) => (
    //     <>
    //       {!text || text === ("<str>" || null || undefined || "") ? "-" : text}
    //     </>
    //   ),
    //   //sorter: (a, b) => a.to.length - b.to.length,
    // },

    // {
    //   title: "No Of Days",
    //   dataIndex: "noofdays",
    //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    // },

    {
      title: "Reason",
      dataIndex: "reason",
      //sorter: (a, b) => a.reason.length - b.reason.length,
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded"
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ textTransform: "capitalize" }}
          >
            <i
              className={
                text === "Declined"
                  ? "fa fa-dot-circle-o text-danger"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-purple"
              }
            />{" "}
            {text}
          </a>
          {/* <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-purple" /> New
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-info" /> Pending
            </a>
            <a
              className="dropdown-item"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#approve_leave"
            >
              <i className="fa fa-dot-circle-o text-success" /> Approved
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-danger" /> Declined
            </a>
          </div> */}
        </div>
      ),
      //sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <button
          style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
          data-bs-toggle="modal"
          data-bs-target="#Leave_info_modal"
          className=" btn-primary submit-btn1"
          onClick={(e) => setViewLeaveDetails(text)}
          disabled={
            rolePermissions["Half day"] === "Edit/Create/Approve" ? false : true
          }
        >
          View
        </button>
      ),
    },
  ];

  const column1 = [
    {
      title: "SNo.",
      dataIndex: "sno",
      fixed: "left",
      width: 80,
    },
    {
      title: "Employee name",
      dataIndex: "name",
      fixed: "left",
      // width: "fit-content",
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <TooltipOne placement="topLeft" title={name}>
          {name}
        </TooltipOne>
      ),
      sorter: (a, b) => a.name?.localeCompare(b?.name),
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.department?.localeCompare(b?.department),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      sorter: (a, b) => a.designation?.localeCompare(b?.designation),
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      sorter: (a, b) => a.status?.localeCompare(b?.status),
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded "
            href="#"
            style={{ textTransform: "capitalize" }}
          >
            <i
              className={
                text === "Present"
                  ? "fa fa-dot-circle-o text-success"
                  : text === "present"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text}
          </a>
        </div>
      ),
    },
    {
      title: "Mobile Number",
      dataIndex: "mob_num",
      align: "center",
      render: (text, record) => <>{text === "" ? "-" : text}</>,
      //   sorter: (a, b) => a.overtime.length - b.overtime.length,
    },

    {
      title: "Punch In",
      dataIndex: "clock_in",
      align: "center",
      render: (text, record) => <>{text || "-"}</>,
      //   sorter: (a, b) => a.punchin.length - b.punchin.length,
    },
    {
      title: "Punch Out",
      dataIndex: "clock_out",
      align: "center",
      render: (text, record) => <>{text || "-"}</>,
      //   sorter: (a, b) => a.punchout.length - b.punchout.length,
    },
    {
      title: "Late In",
      dataIndex: "late_in",
      render: (text, record) => <>{text === "" ? "-" : text}</>,
      align: "center",
      //   sorter: (a, b) => a.break.length - b.break.length,
    },

    // {
    //   title: "Location",
    //   dataIndex: "location",
    // //   sorter: (a, b) => a.location.length - b.location.length,
    // },
    {
      title: "Location",
      dataIndex: "location",
      render: (text, record) => <>{text === "" ? "-" : text}</>,
      align: "center",
      //   sorter: (a, b) => a.break.length - b.break.length,
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      render: (text, record) => <>{text === null ? "-" : text}</>,
      align: "center",
      //   sorter: (a, b) => a.break.length - b.break.length,
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      render: (text, record) => <>{text === null ? "-" : text}</>,
      align: "center",
      //   sorter: (a, b) => a.break.length - b.break.length,
    },
    {
      title: "Break",
      dataIndex: "break",
      align: "center",
      render: (text, record) => <>{text === "" ? "-" : text}</>,
      //   sorter: (a, b) => a.break.length - b.break.length,
    },

    {
      title: "Productive Hours",
      dataIndex: "prohrs",
      align: "center",
      render: (text, record) => (
        <>
          {parseInt(text) <= 4 ? (
            <Lozenge appearance="removed">{text === "" ? "-" : text}</Lozenge>
          ) : parseInt(text) > 4 && parseInt(text) < 6 ? (
            <Lozenge appearance="moved">{text === "" ? "-" : text}</Lozenge>
          ) : (
            <Lozenge appearance="success">{text === "" ? "-" : text}</Lozenge>
          )}
        </>
      ),
    },
    {
      title: "Overtime",
      dataIndex: "overtime",
      align: "center",
      render: (text, record) => <>{text === "" ? "-" : text}</>,
      //   sorter: (a, b) => a.overtime.length - b.overtime.length,
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <>
          <IconButton
            aria-label="more"
            onClick={(e) => handleClick2(e, text)}
            aria-haspopup="true"
            aria-controls="long-menu"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl2}
            keepMounted
            onClose={handleClose2}
            open={open2}
          >
            <MenuItem
              onClick={handleClose2}
              data-bs-toggle="modal"
              data-bs-target="#view_tracking"
            >
              View Geo Tracking
            </MenuItem>
            <MenuItem
              onClick={handleClose2}
              data-bs-toggle="modal"
              data-bs-target="#view_tagging"
            >
              View Geo Tagging
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  const columnr = [
    {
      title: "SNo.",
      dataIndex: "sno",
      align: "center",
      key: "key",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Employee name",
      dataIndex: "name",
      fixed: "left",
      key: "key",
      sorter: (a, b) => a.name?.localeCompare(b?.name),
      //align: "center",
      //   sorter: (a, b) => a.break.length - b.break.length,
    },

    {
      title: "Date",
      dataIndex: "date",
      align: "center",
      key: "key",
      //   sorter: (a, b) => a.date.length - b.date.length,
    },

    // {
    //   title: "Location",
    //   dataIndex: "location",
    // //   sorter: (a, b) => a.location.length - b.location.length,
    // },
    {
      title: "Location",
      dataIndex: "location",
      key: "key",
      sorter: (a, b) => a.location?.localeCompare(b?.location),
      render: (text, record) => (
        <>
          {!text || text === ("<str>" || null || undefined || "") ? "-" : text}
        </>
      ),
    },
    {
      title: "Mood",
      dataIndex: "mood",
      key: "key",
      align: "center",
      //   sorter: (a, b) => a.break.length - b.break.length,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "key",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded "
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ textTransform: "capitalize" }}
          >
            <i
              className={
                text === "Declined"
                  ? "fa fa-dot-circle-o text-danger"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-purple"
              }
            />{" "}
            {text}
          </a>
          {/* <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-purple" /> New
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-info" /> Pending
            </a>
            <a
              className="dropdown-item"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#approve_leave"
            >
              <i className="fa fa-dot-circle-o text-success" /> Approved
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-danger" /> Declined
            </a>
          </div> */}
        </div>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <button
          style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
          data-bs-toggle="modal"
          data-bs-target="#view_regular"
          className=" btn-primary submit-btn1"
          onClick={(e) => setViewLeaveDetails(text)}
          disabled={
            rolePermissions["Regularies"] === "Edit/Create/Approve"
              ? false
              : true
          }
        >
          View
        </button>
      ),
    },
  ];

  const columnt = [
    {
      title: "SNo.",
      dataIndex: "sno",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
      // render: (text, record) => {
      //   <>
      //     {!text || text === ("<str>" || null || undefined || "")
      //       ? "-"
      //       : text?.split("-").reverse().join("-")}
      //   </>;
      // },
    },
    {
      title: "Time",
      dataIndex: "time",
      align: "center",
      render: (text, record) => (
        <>
          {!text || text === ("<str>" || null || undefined || "")
            ? "-"
            : text?.slice(0, 8)}
        </>
      ),
    },
    // {
    //   title: "ClockOut Time",
    //   dataIndex: "clockout_tm",
    //   align: "center",
    //   render: (text, record) => (
    //     <>
    //       {!text || text === ("<str>" || null || undefined || "")
    //         ? "-"
    //         : text?.slice(0, 8)}
    //     </>
    //   ),
    // },

    // {
    //   title: "ClockOut Location",
    //   dataIndex: "clockout_loc",
    //   align: "center",
    //   render: (text, record) => (
    //     <>
    //       {!text || text === ("<str>" || null || undefined || "") ? "-" : text}
    //     </>
    //   ),
    // },
    {
      title: "Latitude",
      dataIndex: "lat",
      // sorter: (a, b) => a.loc?.localeCompare(b?.loc),
      render: (text, record) => (
        <>
          {!text || text === ("<str>" || null || undefined || "") ? "-" : text}
        </>
      ),
    },

    {
      title: "Longitude",
      dataIndex: "lng",
      // sorter: (a, b) => a.loc?.localeCompare(b?.loc),
      render: (text, record) => (
        <>
          {!text || text === ("<str>" || null || undefined || "") ? "-" : text}
        </>
      ),
    },

    {
      title: "Address",
      dataIndex: "location",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <>
          {text === null ? (
            <button
              className="choose-file-btn"
              style={{
                background: "#68B266",
                width: "80px",
                fontSize: "14px",
              }}
              onClick={() => handleGetA(record)}
            >
              Get Address
            </button>
          ) : (
            <TooltipOne placement="topLeft" title={text}>
              {text}
            </TooltipOne>
          )}
        </>
      ),
    },
  ];
  const columnt1 = [
    {
      title: "SNo.",
      dataIndex: "sno",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
    },
    {
      title: "clock-in Lat",
      dataIndex: "clockin_lat",
      render: (text, record) => (
        <>
          {!text || text === ("<str>" || null || undefined || "") ? "-" : text}
        </>
      ),
    },
    {
      title: "clock-in Long",
      dataIndex: "clockin_long",
      render: (text, record) => (
        <>
          {!text || text === ("<str>" || null || undefined || "") ? "-" : text}
        </>
      ),
    },

    {
      title: "Clock-in Address",
      dataIndex: "clockin_add",
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <TooltipOne placement="topLeft" title={name}>
          {name}
        </TooltipOne>
      ),
    },
    {
      title: "clock-out Lat",
      dataIndex: "clockout_lat",
      render: (text, record) => (
        <>
          {!text || text === ("<str>" || null || undefined || "") ? "-" : text}
        </>
      ),
    },
    {
      title: "clock-out Long",
      dataIndex: "clockout_long",
      render: (text, record) => (
        <>
          {!text || text === ("<str>" || null || undefined || "") ? "-" : text}
        </>
      ),
    },
    {
      title: "Clock-out Address",
      dataIndex: "clockout_add",
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <TooltipOne placement="topLeft" title={name}>
          {name}
        </TooltipOne>
      ),
    },
  ];
  const column2 = [
    {
      title: "SNo.",
      dataIndex: "sno",
      align: "center",
      fixed: "left",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    // {
    //   title: "Name",
    //   dataIndex: "name",
    //   fixed: "left",
    //   sorter: (a, b) => a.name?.localeCompare(b?.name),
    // },
    {
      title: "Employee name",
      dataIndex: "name",
      fixed: "left",
      // width: "fit-content",
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <TooltipOne placement="topLeft" title={name}>
          {name}
        </TooltipOne>
      ),
      sorter: (a, b) => a.name?.localeCompare(b?.name),
    },
    {
      title: "Department",
      dataIndex: "dept",
      sorter: (a, b) => a.dept?.localeCompare(b?.dept),
    },
    {
      title: "Designation",
      dataIndex: "desg",
      sorter: (a, b) => a.desg?.localeCompare(b?.desg),
    },
    {
      title: "Working Days",
      dataIndex: "days",
      align: "center",
      sorter: (a, b) => a.days - b.days,
    },

    {
      title: "In Office",
      dataIndex: "office",
      align: "center",
      sorter: (a, b) => a.office - b.office,
    },
    {
      title: "Outdoor",
      dataIndex: "outdoor",
      align: "center",
      sorter: (a, b) => a.outdoor - b.outdoor,
    },
    // {
    //   title: "Location",
    //   dataIndex: "location",
    // //   sorter: (a, b) => a.location.length - b.location.length,
    // },
    {
      title: "Working From Home",
      dataIndex: "wfh",
      align: "center",
      sorter: (a, b) => a.wfh - b.wfh,
    },
    {
      title: "Break",
      dataIndex: "break",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.break, "h:mm:ss") - moment(b.break, "h:mm:ss"),
      },
    },
    {
      title: "Late In",
      dataIndex: "late_in",
      align: "center",
      sorter: (a, b) => a.late_in - b.late_in,
    },
    {
      title: "Early Out",
      dataIndex: "early_out",
      align: "center",
      sorter: (a, b) => a.early_out - b.early_out,
    },
    {
      title: "Productive Hours",
      dataIndex: "prohrs",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.prohrs, "h:mm:ss") - moment(b.prohrs, "h:mm:ss"),
      },
    },
    {
      title: "Overtime",
      dataIndex: "overtime",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.overtime, "h:mm:ss") - moment(b.overtime, "h:mm:ss"),
      },
    },
  ];
  const exportInstance = new tableExport(
    timePeriod === "today"
      ? today
          .filter((x) => x.name == (name1 == "" ? x.name : name1))
          .filter((x) => x.empid == (empId == "" ? x.empid : empId))
          .filter(
            (a) =>
              a.department == (department1 == "" ? a.department : department1)
          )
          .filter(
            (a) =>
              a.designation == (designation == "" ? a.designation : designation)
          )
          .filter((a) => a.status == (status1 == "" ? a.status : status1))
      : today
          .filter((x) => x.name == (name1 == "" ? x.name : name1))
          .filter((a) => a.dept == (department1 == "" ? a.dept : department1))
          .filter((a) => a.desg == (designation == "" ? a.desg : designation))
          .filter((a) => a.status == (status1 == "" ? a.status : status1)),

    timePeriod === "today" ? column1 : column2
  );
  const downloadExcel = (jsonData) => {
    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(jsonData);

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert workbook to binary data
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    // Function to convert binary data to octet stream
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    };

    // Create a Blob from the binary data
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    // Create a link element
    const link = document.createElement("a");

    // Set the download attribute with a filename
    link.download = "data.xlsx";

    // Create a URL for the Blob and set it as the href attribute
    link.href = URL.createObjectURL(blob);

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the download by simulating a click
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  /*Upload Biometric Excel   */
  const handleChange1 = (event) => {
    // const fileUploaded = event.target.files[0];
    // setBioExcel(fileUploaded);
    // uploadAttendance();
    Swal.fire({
      // title: "Attendance Report Downloading !",
      html: "Your Attendance upload is in progress. This may take a few moments",
      // timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        // const timer = Swal.getPopup().querySelector("b");
        // timerInterval = setInterval(() => {
        //   timer.textContent = `${Swal.getTimerLeft()}`;
        // }, 100);
      },
    });
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/bioattendance/`, formData)
      .then((res) => {
        downloadExcel(res.data);
        Swal.fire({
          icon: "success",
          text: " Attendance uploaded successfully",
        });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  // const handleClick = (event) => {
  //   hiddenFileInput.current.click();
  // };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick1 = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // console.log(bioExcel);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={
                matchsmexact
                  ? { width: "250px", overflow: "auto" }
                  : { minWidth: "200px", overflow: "auto" }
              }
            >
              <DateRangePicker
                onChange={(item) => setState([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={state}
                direction="horizontal"
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                bottom: "-15px",
                left: "28%",
                // width: "100px",
                // padding: "3px 5px",
                // background: "#3d91ff",
                // color: "white",
                // borderRadius: "8px",
                // cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",

                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  width: "100px",
                  padding: "3px 5px",
                  background: "#3d91ff",
                  color: "white",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => handleClose()}
              >
                Cancel
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  width: "100px",
                  padding: "3px 5px",
                  background: "#3d91ff",
                  color: "white",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => handleClose()}
              >
                Apply
              </Box>
            </Box>
          </Box>
        </Modal>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setCurrDisplayToggle("")}
                  >
                    Attendance
                  </span>

                  {currDisplayToggle === "wfh" && (
                    <span style={{ color: "#6c757d" }}> / WFH</span>
                  )}

                  {currDisplayToggle === "regular" && (
                    <span style={{ color: "#6c757d" }}> / regularize</span>
                  )}

                  {currDisplayToggle === "compoff" && (
                    <span style={{ color: "#6c757d" }}> / compoff</span>
                  )}

                  {currDisplayToggle === "halfday" && (
                    <span style={{ color: "#6c757d" }} s>
                      {" "}
                      / halfday
                    </span>
                  )}
                </h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  {/* <Link
                    // to="/attendance-grid"
                    className="grid-view btn btn-link active"
                    onClick={() => setShowTable(false)}
                  >
                    <i className="fa fa-th" />
                  </Link>
                  <Link
                    // to="/attendance-list"
                    className="list-view btn btn-link"
                    onClick={() => setShowTable(true)}
                  >
                    <i className="fa fa-bars" />
                  </Link> */}
                  {rolePermissions["Work From Home"] !== "NoAccess" && (
                    <button
                      className="btn btn-success btn-block  "
                      style={{
                        padding: "8px 23px",
                        fontSize: "15px",
                        background: " #973FCF",
                        border: "none",
                        color: "white",
                      }}
                      onClick={() => switchDisplay("wfh")}
                    >
                      Work from home
                    </button>
                  )}
                  {rolePermissions["Regularies"] !== "NoAccess" && (
                    <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "8px 23px",
                        fontSize: "15px",
                        background: " #ED9D44",
                        border: "none",
                        color: "white",
                      }}
                      onClick={() => {
                        switchDisplay("regular");
                      }}
                    >
                      Regularise
                    </button>
                  )}
                  {rolePermissions["comp off"] !== "NoAccess" && (
                    <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "8px 23px",
                        fontSize: "15px",
                        background: " #3298DB",
                        border: "none",
                        color: "white",
                      }}
                      onClick={() => switchDisplay("compoff")}
                    >
                      Comp Off Leave
                    </button>
                  )}
                  {rolePermissions["Half day"] !== "NoAccess" && (
                    <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "8px 23px",
                        fontSize: "15px",
                        background: " #2b8f6f",
                        border: "none",
                        color: "white",
                      }}
                      onClick={() => {
                        switchDisplay("halfday");
                      }}
                    >
                      Half Day
                    </button>
                  )}
                  {/* <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: " #2b8f6f",
                      border: "none",
                      color: "white",
                    }}
                    onClick={handleClick}
                  >
                    <FiUpload /> Upload Biometric Attendance
                  </button> */}
                  <div className="view-icons">
                    <button
                      className=" "
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "20px 23px",
                        fontSize: "15px",
                        background: "#3298DB",
                        border: "none",
                        color: "white",
                        height: "30px",
                        borderRadius: "10px",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#add_task_board"
                      href="#"
                      classname="edit-icon"
                      // onClick={() => setApplyLeave("wfh")}
                    >
                      <Box>
                        <Tooltip title="Upload Biometric Attendance">
                          <IconButton
                            onClick={handleClick}
                            size="small"
                            color="white"
                            // sx={{ ml: -3 }}
                            aria-controls={open1 ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open1 ? "true" : "false"}
                            // className={classes.button}
                          >
                            <p
                              style={{
                                fontSize: "14px",
                                marginTop: "15px",
                                color: "white",
                                fontWeight: "500",
                              }}
                            >
                              <FiUpload /> Upload Biometric Attendance
                            </p>
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </button>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open1}
                      onClose={handleClose1}
                      onClick={handleClose1}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                      }}
                    >
                      <MenuItem>
                        <a
                          // onClick={() => handleExcelDownload()}
                          href={attendanceSheet}
                          download
                          target="_blank"
                        >
                          Download Template
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <a
                          onClick={(e) => handleClick1(e)}
                          // data-bs-toggle="modal"
                          // data-bs-target="#experience_info"
                          // href="#"
                          // appearance="primary"
                          // onClick={openModal}
                        >
                          Upload File
                        </a>
                      </MenuItem>
                    </Menu>
                  </div>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange1}
                    style={{ display: "none" }}
                  />
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#2b8f6f",
                      border: "none",
                      color: "white",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#monthlyAttendanceReport"
                    // onClick={() => {
                    //   handleDownloadCsv();
                    // }}
                  >
                    <FiDownload /> Monthly Attendance
                  </button>

                  {/* <a
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: " #2b8f6f",
                      border: "none",
                      color: "white",
                    }}
                    download
                    onClick={() => {
                      exportInstance.download("Attendance", "xlsx");
                    }}
                  >
                    Download Attendance Report
                  </a> */}
                  {/* <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#FF7676",
                      border: "none",
                      color: "white",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#Leave_info_modal"
                    onClick={() => setApplyLeave("shiftchange")}
                  >
                    Shift Change
                  </button> */}
                </div>
              </div>
            </div>
          </div>

          {currDisplayToggle === "" && (
            <>
              <div className="row filter-row">
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="col-md-12">
                    <input
                      type="search"
                      placeholder="Employee Name..."
                      className="project-search col-md-12"
                      onChange={(e) => setName1(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      inputId="single-select-example"
                      className="single-select"
                      classNamePrefix="react-select"
                      isClearable
                      placeholder="Employee code"
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      options={[
                        ...new Set(today?.map((item) => item.emp_code)),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        empId !== ""
                          ? {
                              label: empId,
                              value: empId,
                            }
                          : null
                      }
                      onChange={(e) => setEmpId(e ? e.value : "")}
                      //className="select floating"
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      placeholder="Select Department"
                      isClearable
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      options={[
                        ...new Set(
                          today?.map((item) => item.department || item.dept)
                        ),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        department1 !== ""
                          ? {
                              label: department1,
                              value: department1,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setDepartment1(e ? e.value : "");
                      }}
                      className="select floating"
                      style={{
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      placeholder="Select Designation"
                      options={[
                        ...new Set(
                          today?.map((item) => item.designation || item.desg)
                        ),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        designation !== ""
                          ? {
                              label: designation,
                              value: designation,
                            }
                          : null
                      }
                      //isClearable={true}
                      isClearable
                      onChange={(e) => {
                        setDesignation(e ? e.value : "");
                      }}
                      className="select floating"
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      placeholder="Select work location"
                      options={[
                        ...new Set(today?.map((item) => item.office_location)),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        location !== ""
                          ? {
                              label: location,
                              value: location,
                            }
                          : null
                      }
                      //isClearable={true}
                      isClearable
                      onChange={(e) => {
                        setLocation(e ? e.value : "");
                      }}
                      className="select floating"
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>

                {showStatus ? (
                  <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                    <div className="form-group form-focus select-focus">
                      <Select
                        placeholder="Select Status"
                        options={[
                          ...new Set(today?.map((item) => item.status)),
                        ].map((ele) => ({
                          label: ele,
                          value: ele,
                        }))}
                        value={
                          status1 !== ""
                            ? {
                                label: status1,
                                value: status1,
                              }
                            : null
                        }
                        //isClearable={true}
                        isClearable
                        onChange={(e) => {
                          setStatus1(e ? e.value : "");
                        }}
                        className="select floating"
                        style={{
                          zindex: "5",
                          height: "50px",
                          width: "100%",
                          border: "1px solid lightgrey",
                          borderRadius: "3px",
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="card mb-0">
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginRight: "10px",
                    justifyContent: "end",
                  }}
                >
                  <h4 style={{ paddingTop: "7px" }}>
                    {state[0].startDate === state[0].endDate ? (
                      <b>{`Attendance For
                    ${moment(state[0].startDate).format("DD-MM-YYYY")}`}</b>
                    ) : (
                      <b>{`Attendance From
                    ${moment(state[0].startDate).format(
                      "DD-MM-YYYY"
                    )} To ${moment(state[0].endDate).format("DD-MM-YYYY")}`}</b>
                    )}
                  </h4>{" "}
                  &nbsp; &nbsp; &nbsp;
                  <div className="view-icons">
                    {/* <button
                      className="btn btn-success btn-block"
                      style={{
                        padding: "5px 15px",
                        fontSize: "15px",
                        background:
                          timePeriod === "week" ? "#3298DB" : "transparent",
                        border: "none",
                        color: timePeriod === "week" ? "white" : "#3298DB",
                      }}
                      classname="edit-icon"
                      onClick={() => switchToggel("week", "w")}
                    >
                      Week
                    </button> */}

                    {/* <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "5px 15px",
                        fontSize: "15px",
                        background:
                          timePeriod === "today" ? "#3298DB" : "transparent",
                        border: "none",
                        color: timePeriod === "today" ? "white" : "#3298DB",
                      }}
                      onClick={() => switchToggel("today", "d")}
                    >
                      Today
                    </button> */}

                    <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "5px 15px",
                        fontSize: "15px",
                        background: "#3298DB",
                        border: "none",
                        color: "white",
                      }}
                      onClick={handleOpen}
                    >
                      Select Date(s)
                    </button>

                    {/* <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "8px 23px",
                        fontSize: "15px",
                        background:
                          timePeriod === "month" ? "#3298DB" : "transparent",
                        border: "none",
                        color: timePeriod === "month" ? "white" : "#3298DB",
                      }}
                
                    >
                      Month
                    </button> */}

                    {/* <button
                      className="btn btn-success btn-block"
                      style={{
                        borderRadius: "8px",
                        fontSize: "15px",
                        border: "none",
                        background:
                          timePeriod === "month" ? "#3298DB" : "transparent",
                        color: timePeriod === "month" ? "white" : "#3298DB",
                        padding: "5px 15px",
                      }}
                      onClick={() => switchToggel("month", "ym")}
                    >
                      Month{" "}
                    </button> */}

                    {/*
                    <Space direction="vertical" size={12}>
                      <RangePicker onChange={(e) => {
                        setDates(e.map(item => {
                          return moment(item).format('DD-MM-YYYY')
                        }))
                      }} />
                    </Space>
                    */}

                    {/* <input
                      className="btn btn-success btn-block"
                      style={{
                        borderRadius: "5px",
                        border: "1px",
                        height: "30px",
                        background:timePeriod === "month" ? "#3298DB" : "transparent",
                        color: timePeriod === "month" ? "white" : "#3298DB",
                        padding: "10px",
                      }}
                      type="month"
                      value={monthValue}
                      onChange={(e) => switchToggel("month", "ym", e)}
                      onChange={(e) => setMonthValue(e.target.value)}
                    /> */}
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    {timePeriod === "today" ? (
                      <Table
                        pagination={{
                          total: today
                            .filter((a) => {
                              return name1.toLocaleLowerCase() === ""
                                ? a
                                : a.name
                                    .toLocaleLowerCase()
                                    .includes(name1.toLocaleLowerCase());
                            })
                            .filter(
                              (a) =>
                                a.department ==
                                (department1 == "" ? a.department : department1)
                            )
                            .filter(
                              (a) =>
                                a.designation ==
                                (designation == ""
                                  ? a.designation
                                  : designation)
                            )
                            .filter(
                              (a) =>
                                a.office_location ==
                                (location == "" ? a.office_location : location)
                            )
                            .filter(
                              (x) =>
                                x.emp_code == (empId == "" ? x.emp_code : empId)
                            )
                            .filter(
                              (a) =>
                                a.status == (status1 == "" ? a.status : status1)
                            ).length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        // style={{ overflowX: "1300" }}
                        columns={
                          moment(state[0].startDate).format("YYYY-MM-DD") ===
                          moment(state[0].endDate).format("YYYY-MM-DD")
                            ? column1
                            : column2
                        }
                        bordered
                        dataSource={today
                          .filter((a) => {
                            return name1.toLocaleLowerCase() === ""
                              ? a
                              : a.name
                                  .toLocaleLowerCase()
                                  .includes(name1.toLocaleLowerCase());
                          })
                          .filter(
                            (a) =>
                              a.department ==
                              (department1 == "" ? a.department : department1)
                          )
                          .filter(
                            (x) =>
                              x.emp_code == (empId == "" ? x.emp_code : empId)
                          )
                          .filter(
                            (a) =>
                              a.office_location ==
                              (location == "" ? a.office_location : location)
                          )
                          .filter(
                            (a) =>
                              a.designation ==
                              (designation == "" ? a.designation : designation)
                          )
                          .filter(
                            (a) =>
                              a.status == (status1 == "" ? a.status : status1)
                          )}
                        rowKey={(record) => record.id}
                        onChange={handleChange}
                        exportable
                        // searchable
                        exportableProps={{ showColumnPicker: true }}
                        // searchableProps={{ fuzzySearch: true }}
                        loading={isLoading}
                        scroll={{ x: 2100 }}
                        // onChange={this.handleTableChange}
                      />
                    ) : (
                      <Table
                        pagination={{
                          total: today.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={column2}
                        bordered
                        dataSource={today
                          .filter(
                            (x) => x.name == (name1 == "" ? x.name : name1)
                          )
                          .filter(
                            (a) =>
                              a.dept ==
                              (department1 == "" ? a.dept : department1)
                          )
                          .filter(
                            (a) =>
                              a.desg ==
                              (designation == "" ? a.desg : designation)
                          )}
                        rowKey={(record) => record.id}
                        exportable
                        // searchable
                        exportableProps={{ showColumnPicker: true }}
                        // searchableProps={{ fuzzySearch: true }}
                        loading={isLoading}
                        // onChange={this.handleTableChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {(currDisplayToggle === "wfh" ||
            currDisplayToggle === "compoff" ||
            // currDisplayToggle === "regular" ||
            currDisplayToggle === "halfday") && (
            // <div className="row">
            //   <div className="col-md-12">
            <div className="card mb-0">
              <div className="card-body">
                <div className="table-responsive">
                  <Table
                    className="table-striped"
                    pagination={{
                      total: data.length,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                      onShowSizeChange: onShowSizeChange,
                      itemRender: itemRender,
                    }}
                    style={{ overflowX: "auto" }}
                    columns={
                      currDisplayToggle === "halfday" ? column4 : column3
                    }
                    //bordered
                    dataSource={data}
                    rowKey={(record) => record.id}
                    loading={isLoading}
                    exportable
                    // searchable
                    exportableProps={{ showColumnPicker: true }}
                    // searchableProps={{ fuzzySearch: true }}
                  />
                </div>
              </div>
              {/* </div>
        </div> */}
            </div>
          )}
          {currDisplayToggle === "regular" && (
            // <div className="row">
            //   <div className="col-md-12">
            <div className="card mb-0">
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginRight: "10px",
                  justifyContent: "end",
                }}
              >
                <div className="view-icons">
                  <input
                    type="month"
                    value={monthValue}
                    onChange={(e) => setMonthValue(e.target.value)}
                  />
                </div>
              </div>
              <div className="card-body">
                {selectedRowKeys.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "14px",
                      marginBottom: "-30px",
                    }}
                  >
                    <div className="regularize-part">
                      <button
                        style={{
                          border: "none",
                          outline: "none",
                          padding: "5px 30px",
                          border: "1.5px solid #7F7F7F",
                          borderRadius: "6px",
                          color: "#7F7F7F",
                        }}
                        onClick={(e) => handleBulkAction(e, true)}
                      >
                        Reject
                      </button>
                    </div>
                    <div className="regularize-part">
                      <button
                        style={{
                          border: "none",
                          outline: "none",
                          padding: "6px 30px",
                          background: "#2B8F6F",

                          color: "#fff",
                          borderRadius: "6px",
                        }}
                        onClick={(e) => handleBulkAction(e, false)}
                      >
                        Approve
                      </button>
                    </div>
                  </div>
                )}
                <div className="table-responsive">
                  <Table
                    // className="table-striped"
                    pagination={{
                      total: data.length,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                      onShowSizeChange: onShowSizeChange,
                      itemRender: itemRender,
                    }}
                    rowSelection={rowSelection}
                    style={{ overflowX: "auto" }}
                    columns={columnr}
                    //bordered
                    dataSource={data}
                    // rowKey={(record) => record.id}
                    loading={isLoading}
                    exportable
                    // searchable
                    exportableProps={{ showColumnPicker: true }}
                    // searchableProps={{ fuzzySearch: true }}
                  />
                </div>
              </div>
              {/* </div>
        </div> */}
            </div>
          )}

          {/* {!showTable && <Calendar />}
          {showTable && <Attendancetable />} */}
        </div>
      </div>
      {/* /Page Content */}

      <div
        id="Leave_info_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content" style={{ width: "615px" }}>
            <div className="modal-header">
              <h5
                className="modal-title"
                style={{ marginTop: "10px", fontWeight: "600" }}
              >
                {" "}
                {applyLeave === "wfh"
                  ? "Request for Work from home"
                  : applyLeave === "regular"
                  ? "Request to Regularise Leave"
                  : applyLeave === "compoff"
                  ? "Request for Comp off Leave"
                  : "Request for Half Day"}{" "}
              </h5>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button> */}
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                <img src={closeIcon} />
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  {applyLeave === "regular" && (
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Date</label>
                        <div>
                          <input
                            disabled
                            className="form-control date"
                            type="date"
                            value={viewLeaveDetails?.date
                              ?.split("-")
                              ?.reverse()
                              ?.join("-")}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {applyLeave === "regular" ? (
                    <>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Location</label>
                          {/* <input className="form-control" type="date" /> */}
                          <Select
                            disabled
                            //options={work_location}
                            value={viewLeaveDetails.location}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Mood</label>
                          {/* <input className="form-control" type="date" /> */}
                          <Select
                            //options={work_mood}
                            value={viewLeaveDetails.mood}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-6">
                        <div className="form-group">
                          {applyLeave !== "halfday" ? (
                            <label>From</label>
                          ) : (
                            <label>Date</label>
                          )}
                          <div>
                            <input
                              disabled
                              className="form-control datetimepicker"
                              type="date"
                              value={
                                applyLeave !== "halfday"
                                  ? viewLeaveDetails.from_date
                                      ?.split("-")
                                      ?.reverse()
                                      ?.join("-")
                                  : viewLeaveDetails.on_date
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {applyLeave !== "halfday" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>To</label>
                            <input
                              disabled
                              className="form-control datetimepicker"
                              type="date"
                              value={viewLeaveDetails.to_date
                                ?.split("-")
                                ?.reverse()
                                ?.join("-")}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Reason </label>
                      <textarea
                        disabled
                        rows={4}
                        className="form-control"
                        value={viewLeaveDetails.reason}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="submit-section"
                  style={{ justifyContent: "space-between" }}
                >
                  <button
                    type="button"
                    className="btn btn-success btn-block w-100 "
                    //data-bs-dismiss="modal"
                    style={{ background: "transparent", color: "#18838D" }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    //onClick={(e) => handleApproveLeave(e, true)}
                  >
                    Reject
                  </button>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  {/* <button className="btn btn-success btn-block w-100">
                        Approved
                      </button> */}
                  {viewLeaveDetails.status === "Pending" && (
                    <button
                      className="btn btn-success btn-block w-100"
                      data-bs-dismiss="modal"
                      onClick={(e) => handleApproveLeave(e, false)}
                    >
                      Accept
                    </button>
                  )}
                  {viewLeaveDetails.status === "Rejected" && (
                    <button
                      className="btn btn-success btn-block w-100"
                      data-bs-dismiss="modal"
                      onClick={(e) => handleApproveLeave(e, false)}
                    >
                      Accept
                    </button>
                  )}
                  {viewLeaveDetails.status === "Approved by Manager" &&
                    role === 2 && (
                      <button
                        className="btn btn-success btn-block w-100"
                        data-bs-dismiss="modal"
                        onClick={(e) => handleApproveLeave(e, false)}
                      >
                        Accept
                      </button>
                    )}
                  {viewLeaveDetails.status === "Approved by Manager" &&
                    role === 3 && (
                      <button
                        className="btn btn-success btn-block w-100"
                        data-bs-dismiss="modal"
                        // onClick={(e) => handleApproveLeave(e, false)}
                        disabled
                      >
                        Approved
                      </button>
                    )}
                  {viewLeaveDetails.status === "Approved" && role === 3 && (
                    <button
                      className="btn btn-success btn-block w-100"
                      data-bs-dismiss="modal"
                      // onClick={(e) => handleApproveLeave(e, false)}
                      disabled
                    >
                      Approved
                    </button>
                  )}
                  {viewLeaveDetails.status === "Approved" && role === 4 && (
                    <button
                      className="btn btn-success btn-block w-100"
                      data-bs-dismiss="modal"
                      // onClick={(e) => handleApproveLeave(e, false)}
                      disabled
                    >
                      Approved
                    </button>
                  )}
                  {viewLeaveDetails.status === "Approved" && role === 2 && (
                    <button
                      data-bs-dismiss="modal"
                      className="btn btn-success btn-block w-100"
                      // onClick={(e) => handleApproveLeave(e, false)}
                      disabled
                    >
                      Approved
                    </button>
                  )}
                </div>

                {/* <div className="submit-section">
                  <button
                    className=" btn-primary "
                    style={{
                      background: "#DF4E2F",
                      width: "94px",
                      height: "38px",
                      borderRadius: "5px",
                      fontSize: "16px",
                    }}
                    data-bs-dismiss="modal"
                    onClick={(e) =>
                      applyLeave === "wfh"
                        ? handleWorkFromHome(e)
                        : applyLeave === "compoff"
                        ? handleCompOff(e)
                        : applyLeave === "halfday"
                        ? handleHalfDay(e)
                        : handleRegular(e)
                    }
                  >
                    Submit
                  </button>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>

      <div id="view_regular" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                style={{ marginTop: "10px", fontWeight: "600" }}
              >
                Regularise Request
              </h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                <img src={closeIcon} />
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row col-sm-12">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        Date <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          disabled
                          className="form-control datetimepicker"
                          type="date"
                          value={viewLeaveDetails.date
                            ?.split("-")
                            ?.reverse()
                            ?.join("-")}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <label>
                      Remaining Leaves <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      defaultValue={12}
                      type="text"
                    />
                  </div> */}
                  <div className="form-group">
                    <label>Reason</label>
                    <textarea
                      disabled
                      rows={4}
                      className="form-control"
                      value={viewLeaveDetails.reason}
                    />
                  </div>
                  <div
                    className="submit-section"
                    style={{ justifyContent: "space-between" }}
                  >
                    <button
                      type="button"
                      className="btn btn-success btn-block w-100 "
                      //data-bs-dismiss="modal"
                      style={{ background: "transparent", color: "#18838D" }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      //onClick={(e) => handleApproveLeave(e, true)}
                    >
                      Reject
                    </button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    {/* <button className="btn btn-success btn-block w-100">
                        Approved
                      </button> */}
                    {viewLeaveDetails.status === "Pending" && (
                      <button
                        className="btn btn-success btn-block w-100"
                        data-bs-dismiss="modal"
                        onClick={(e) => handleApproveLeave(e, false)}
                      >
                        Accept
                      </button>
                    )}
                    {viewLeaveDetails.status === "Rejected" && (
                      <button
                        className="btn btn-success btn-block w-100"
                        data-bs-dismiss="modal"
                        onClick={(e) => handleApproveLeave(e, false)}
                      >
                        Accept
                      </button>
                    )}
                    {viewLeaveDetails.status === "Approved by Manager" &&
                      role === 2 && (
                        <button
                          className="btn btn-success btn-block w-100"
                          data-bs-dismiss="modal"
                          onClick={(e) => handleApproveLeave(e, false)}
                        >
                          Accept
                        </button>
                      )}
                    {viewLeaveDetails.status === "Approved by Manager" &&
                      role === 3 && (
                        <button
                          className="btn btn-success btn-block w-100"
                          data-bs-dismiss="modal"
                          // onClick={(e) => handleApproveLeave(e, false)}
                          disabled
                        >
                          Approved
                        </button>
                      )}
                    {viewLeaveDetails.status === "Approved" && role === 3 && (
                      <button
                        className="btn btn-success btn-block w-100"
                        data-bs-dismiss="modal"
                        // onClick={(e) => handleApproveLeave(e, false)}
                        disabled
                      >
                        Approved
                      </button>
                    )}
                    {viewLeaveDetails.status === "Approved" && role === 2 && (
                      <button
                        data-bs-dismiss="modal"
                        className="btn btn-success btn-block w-100"
                        // onClick={(e) => handleApproveLeave(e, false)}
                        disabled
                      >
                        Approved
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal custom-modal fade"
        role="dialog"
        id="exampleModal"
        // tabindex="-1"
        // aria-labelledby="exampleModalLabel"
        // aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <form>
                <div class="form-group">
                  <label for="message-text" class="col-form-label">
                    <b>Reason:</b>
                  </label>
                  <textarea
                    class="form-control"
                    id="message-text"
                    value={declineReason}
                    onChange={(e) => setDeclineReason(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="submit-btn2"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                onClick={(e) => handleApproveLeave(e, true)}
                data-bs-dismiss="modal"
                className="submit-btn2"
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Monthly attendance report */}

      <div
        className="modal custom-modal fade"
        role="dialog"
        id="monthlyAttendanceReport"
        // tabindex="-1"
        // aria-labelledby="exampleModalLabel"
        // aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                style={{ marginTop: "10px", fontWeight: "600" }}
              >
                Generate Monthly Report
              </h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                <img src={closeIcon} />
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group">
                  <label for="message-text" class="col-form-label">
                    <b>Select Month :</b>
                  </label>
                  <input
                    class="form-control"
                    id="message-text"
                    min="2024-05"
                    type="month"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                onClick={(e) => handleMonthlyReport()}
                data-bs-dismiss="modal"
                className="submit-btn2"
              >
                Generate
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="view_leave" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                style={{ marginTop: "10px", fontWeight: "600" }}
              >
                View Request
              </h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                <img src={closeIcon} />
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row col-sm-12">
                  <div className="form-group">
                    <label>
                      Leave Type <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled
                      className="form-control datetimepicker"
                      type="text"
                      value={viewLeaveDetails.leavetype}
                    />
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        From <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          disabled
                          className="form-control datetimepicker"
                          type="date"
                          value={data.from_date}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        To <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          disabled
                          className="form-control datetimepicker"
                          type="date"
                          value={data.to_date}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      Number of days <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      type="text"
                      disabled
                      value={viewLeaveDetails.noofdays}
                    />
                  </div>
                  {/* <div className="form-group">
                    <label>
                      Remaining Leaves <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      defaultValue={12}
                      type="text"
                    />
                  </div> */}
                  <div className="form-group">
                    <label>
                      Leave Reason <span className="text-danger">*</span>
                    </label>
                    <textarea
                      disabled
                      rows={4}
                      className="form-control"
                      value={data.reason}
                    />
                  </div>
                  <div
                    className="submit-section"
                    style={{ justifyContent: "space-between" }}
                  >
                    <button
                      className="btn btn-success btn-block w-100 "
                      data-bs-dismiss="modal"
                      style={{ background: "transparent", color: "#18838D" }}
                      onClick={(e) => handleApproveLeave(e, true)}
                    >
                      Reject
                    </button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    {/* <button className="btn btn-success btn-block w-100">
                        Approved
                      </button> */}
                    {viewLeaveDetails.status === "Pending" && (
                      <button
                        className="btn btn-success btn-block w-100"
                        data-bs-dismiss="modal"
                        onClick={(e) => handleApproveLeave(e, false)}
                      >
                        Accept
                      </button>
                    )}
                    {viewLeaveDetails.status === "Rejected" && (
                      <button
                        className="btn btn-success btn-block w-100"
                        data-bs-dismiss="modal"
                        //onClick={(e) => handleApproveLeave(e, false)}
                      >
                        Accept
                      </button>
                    )}
                    {viewLeaveDetails.status === "Approved by Manager" &&
                      role === 2 && (
                        <button
                          className="btn btn-success btn-block w-100"
                          data-bs-dismiss="modal"
                          //onClick={(e) => handleApproveLeave(e, false)}
                        >
                          Accept
                        </button>
                      )}
                    {viewLeaveDetails.status === "Approved by Manager" &&
                      role === 3 && (
                        <button
                          className="btn btn-success btn-block w-100"
                          data-bs-dismiss="modal"
                          // onClick={(e) => handleApproveLeave(e, false)}
                        >
                          Approved
                        </button>
                      )}
                    {viewLeaveDetails.status === "Approved" && role === 3 && (
                      <button
                        className="btn btn-success btn-block w-100"
                        data-bs-dismiss="modal"
                        // onClick={(e) => handleApproveLeave(e, false)}
                      >
                        Approved
                      </button>
                    )}
                    {viewLeaveDetails.status === "Approved" && role === 2 && (
                      <button
                        data-bs-dismiss="modal"
                        className="btn btn-success btn-block w-100"
                        // onClick={(e) => handleApproveLeave(e, false)}
                      >
                        Approved
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* view geo tracking */}

      <div id="view_tracking" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content">
            {/* <div className="modal-header">
              <h5
                className="modal-title"
                style={{ fontWeight: "600" }}
              >
                Regularize Request
              </h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                <img src={closeIcon} />
              </button>
            </div> */}
            <div className="modal-body">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "-10px",
                }}
              >
                <div>
                  <h4>Employee Geo Tracking Details</h4>
                </div>
              </div>
              <div className="row filter-row">
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-12">
                  <div className="form-group form-focus select-focus">
                    <label>Employee Name</label>
                    <Select
                      inputId="single-select-example"
                      className="single-select"
                      classNamePrefix="react-select"
                      isClearable
                      isDisabled
                      placeholder="Employee Name"
                      // options={[...new Set(data?.map((item) => item.name))].map(
                      //   (ele) => ({
                      //     label: ele,
                      //     value: ele,
                      //   })
                      // )}
                      value={{
                        label: viewTracking.name,
                        value: viewTracking.empid,
                      }}
                      // onChange={(e) => setName1(e ? e.value : "")}
                      //className="select floating"
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <label>From Date</label>
                    <input
                      type="date"
                      value={viewTracking.date_from}
                      onChange={(e) =>
                        setViewTracking({
                          ...viewTracking,
                          date_from: e.target.value,
                        })
                      }
                      style={{
                        width: "100%",
                        height: "38px",
                        borderRadius: "3px",
                        border: "1px solid #CCCCCC",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <label>To Date</label>
                    <input
                      type="date"
                      value={viewTracking.date_to}
                      onChange={(e) =>
                        setViewTracking({
                          ...viewTracking,
                          date_to: e.target.value,
                        })
                      }
                      style={{
                        width: "100%",
                        height: "38px",
                        borderRadius: "3px",
                        border: "1px solid #CCCCCC",
                      }}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 mt-3">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#18838D",
                      border: "none",
                      color: "white",
                      marginTop: "5px",
                    }}
                    onClick={() => handleViewTrackingOne()}
                  >
                    Submit
                  </button>
                  {/* <img
                      tabindex="0"
                      data-bs-toggle="tooltip"
                      title="clear all filter"
                      style={{
                        height: "38px",
                        backgroundColor: "#3298DB",
                        padding: "7px 5px",
                        borderRadius: "5px",
                      }}
                      src={FilterOff}
                    /> */}
                </div>
              </div>
              <Map data={data} />
              {/* <div style={{ display: "flex" }}>
                <div>
                  <input type="text" />
                </div>
              </div> */}
              <div className="row col-sm-12">
                <h3>Selected Address: {getA}</h3>
                <div className="col-sm-12">
                  <Table
                    className="table-striped"
                    pagination={{
                      total: data.length,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                      onShowSizeChange: onShowSizeChange,
                      itemRender: itemRender,
                    }}
                    // rowSelection={rowSelection}
                    style={{ overflowX: "auto" }}
                    columns={columnt}
                    // bordered
                    dataSource={data}
                    // rowKey={(record) => record.id}
                    loading={isLoading}
                    exportable
                    // searchable
                    // exportableProps={{ showColumnPicker: true }}
                    // searchableProps={{ fuzzySearch: true }}
                  />
                  {/* {isLoaded && (
                    <GoogleMap
                      center={center}
                      zoom={15}
                      mapContainerStyle={containerStyle}
                      onLoad={(map) => setMap(map)}
                      // onUnmount={onUnmount}
                      options={{
                        zoomControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                      }}
                    >
                      <DirectionsService
                        options={{
                          destination: { lat: 19.756795, lng: 72.954298 },
                          origin: { lat: 19.856795, lng: 72.914298 },
                          travelMode: "DRIVING",
                        }}
                        callback={onDirectionsFetched}
                      >
                      {directions && (
                        <DirectionsRenderer
                          // origin={{ lat: 19.756795, lng: 72.954298 }}
                          // destination={{ lat: 19.856795, lng: 72.914298 }}
                          directions={directions}
                        />
                      )}
                      </DirectionsService>
                      {points.map((point, i) => (
                        <Marker position={point} />
                      ))} 
                    </GoogleMap>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* View Geo Tagging */}
      <div id="view_tagging" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "-10px",
                }}
              >
                <div>
                  <h4>Employee Geo Tagging Details</h4>
                </div>
              </div>
              <div className="row filter-row">
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-12">
                  <div className="form-group form-focus select-focus">
                    <label>Employee Name</label>
                    <Select
                      inputId="single-select-example"
                      className="single-select"
                      classNamePrefix="react-select"
                      isClearable
                      isDisabled
                      placeholder="Employee Name"
                      // options={[...new Set(data?.map((item) => item.name))].map(
                      //   (ele) => ({
                      //     label: ele,
                      //     value: ele,
                      //   })
                      // )}
                      value={{
                        label: viewTracking.name,
                        value: viewTracking.empid,
                      }}
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <label>From Date</label>
                    <input
                      type="date"
                      value={viewTracking.date_from}
                      onChange={(e) =>
                        setViewTracking({
                          ...viewTracking,
                          date_from: e.target.value,
                        })
                      }
                      style={{
                        width: "100%",
                        height: "38px",
                        borderRadius: "3px",
                        border: "1px solid #CCCCCC",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <label>To Date</label>
                    <input
                      type="date"
                      value={viewTracking.date_to}
                      onChange={(e) =>
                        setViewTracking({
                          ...viewTracking,
                          date_to: e.target.value,
                        })
                      }
                      style={{
                        width: "100%",
                        height: "38px",
                        borderRadius: "3px",
                        border: "1px solid #CCCCCC",
                      }}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 mt-3">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#18838D",
                      border: "none",
                      color: "white",
                      marginTop: "5px",
                    }}
                    onClick={() => handleViewTagging()}
                  >
                    Submit
                  </button>
                </div>
              </div>
              {/* <Map data={data} /> */}
              {/* <div style={{ display: "flex" }}>
                <div>
                  <input type="text" />
                </div>
              </div> */}
              <div className="row col-sm-12">
                {/* <h3>Selected Address: {getA}</h3> */}
                <div className="col-sm-12">
                  <Table
                    className="table-striped"
                    pagination={{
                      total: taggingData.length,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                      onShowSizeChange: onShowSizeChange,
                      itemRender: itemRender,
                    }}
                    style={{ overflowX: "auto" }}
                    columns={columnt1}
                    dataSource={taggingData}
                    loading={isLoading}
                    exportable
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAttendance;
