import React, { useEffect, useState } from "react";
//import {Avatar_16,Avatar_02,Avatar_05,Avatar_09,Avatar_10,Avatar_11,
//Avatar_12, Avatar_13, Avatar_01
// } from "../../Entryfile/imagepath"
/*
import Avatar_16 from "../assets/img/profiles/avatar-01.jpg";
import Avatar_02 from "../assets/img/profiles/avatar-01.jpg";
import Avatar_05 from "../assets/img/profiles/avatar-01.jpg";
import Avatar_09 from "../assets/img/profiles/avatar-01.jpg";
import Avatar_10 from "../assets/img/profiles/avatar-01.jpg";
import Avatar_11 from "../assets/img/profiles/avatar-01.jpg";

import Avatar_12 from "../assets/img/profiles/avatar-01.jpg";
import Avatar_13 from "../assets/img/profiles/avatar-01.jpg";
import Avatar_01 from "../assets/img/profiles/avatar-01.jpg";
*/
import { Link } from "react-router-dom";
import Tag, { SimpleTag } from "@atlaskit/tag";
import TagGroup from "@atlaskit/tag-group";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import closeIcon from "../assets/img/profiles/close-circle-fill.svg";
import {
  SBox4,
  SBox5,
  SBox6,
  SButton,
  STypography,
} from "../pages/Projects/projectcss";
import { Box } from "@mui/material";
import moment from "moment";

const Editproject = (props) => {
  const {
    projectDet,
    setProjectDet,
    enddate,
    setEnddate,
    empList,
    setEmpList,
    more,
    setMore,
    handleGetProjects,
    viewState,
    setViewState,
  } = props;

  // const [teamLead, setTeamLead] = useState({
  //   label: "",
  //   value: "",
  //   name: "",
  //   key: "",
  // });

  // useEffect(() => {
  //   setTeamLead({
  //     label:
  //       Object.keys(projectDet).length !== 0
  //         ? projectDet.teamleads
  //           ? projectDet.teamleads[0].name
  //           : ""
  //         : "",
  //     value:
  //       Object.keys(projectDet).length !== 0
  //         ? projectDet.teamleads
  //           ? projectDet.teamleads[0].key
  //           : ""
  //         : "",
  //     name:
  //       Object.keys(projectDet).length !== 0
  //         ? projectDet.teamleads
  //           ? projectDet.teamleads[0].name
  //           : ""
  //         : "",
  //     key:
  //       Object.keys(projectDet).length !== 0
  //         ? projectDet.teamleads
  //           ? projectDet.teamleads[0].key
  //           : ""
  //         : "",
  //   });
  // }, [projectDet]);

  const proend = `${new Date(projectDet.deadline).getFullYear()}-${
    new Date(projectDet.deadline).getMonth() + 1 > 9
      ? new Date(projectDet.deadline).getMonth() + 1
      : `0${new Date(projectDet.deadline).getMonth() + 1}`
  }-${new Date(projectDet.deadline).getDate()}`;

  const conststat = [
    { label: "No Progress", value: "No Progress" },
    { label: "In Progress", value: "In Progress" },
    { label: "Completed", value: "Completed" },
  ];

  const token = sessionStorage.getItem("access_token");

  const handleProEdit = (e) => {
    e.preventDefault();
    if (
      !projectDet.name ||
      !projectDet.description ||
      projectDet.teamleads.length === 0 ||
      projectDet.members.length === 0
      // ||
      // !projectDet.deadline
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields.",
        timer: 1500,
      });
    } else {
      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_URL}/projects/`,
          {
            project_id: projectDet.id,
            empid: sessionStorage.getItem("emp_id"),
            name: projectDet.name,
            description: projectDet.description,
            teamlead: projectDet.teamleads.map((ele) => ele.key),
            // teamlead: [teamLead]
            //   ? [teamLead.key]
            //   : projectDet.teamleads.map((ele) => ele.key),
            members: projectDet.members.map((ele) => ele.key),
            deadline: enddate !== "" ? enddate : projectDet.deadline,
            status: projectDet.status,
            color: projectDet.color,
            drive_link: projectDet.drive_link,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: "Project Updated successfully",
            timer: 3000,
          });
          setMore(null);
          handleGetProjects();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            timer: 3000,
          });
        });
    }
  };

  return (
    <div id="edit_project" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <br />
          <div className="modal-header">
            <STypography variant="h5">
              <b>{viewState ? "View Project" : "Edit Project"}</b>
            </STypography>

            <button type="button" className="close-btn" data-bs-dismiss="modal">
              <img src={closeIcon} />
            </button>
          </div>
          <hr />

          <div className="modal-body" style={{ marginTop: "-20px" }}>
            {viewState ? (
              <>
                <SBox4>
                  <SBox5>
                    <STypography variant="subtitle1">
                      <b>Project Name</b>
                    </STypography>

                    <STypography variant="subtitle1">
                      {projectDet.name}
                    </STypography>
                  </SBox5>

                  <SBox5>
                    <STypography variant="subtitle1">
                      <b>Project Description</b>
                    </STypography>

                    <STypography paragraph>
                      {projectDet.description}
                    </STypography>
                  </SBox5>
                </SBox4>

                <SBox4>
                  <SBox5>
                    <STypography variant="subtitle1">
                      <b>Start Date</b>
                    </STypography>

                    <STypography variant="subtitle1">
                      {projectDet?.creation_date
                        ?.substr(0, 10)
                        ?.split("-")
                        ?.reverse()
                        ?.join("-")}
                    </STypography>
                  </SBox5>

                  <SBox5>
                    <STypography variant="subtitle1">
                      <b>End Date</b>
                    </STypography>

                    <STypography variant="subtitle1">
                      {projectDet?.deadline
                        ?.substr(0, 10)
                        ?.split("-")
                        ?.reverse()
                        ?.join("-")}
                    </STypography>
                  </SBox5>
                </SBox4>

                <SBox4>
                  <SBox5>
                    <STypography variant="subtitle1">
                      <b>Project Creator</b>
                    </STypography>

                    <STypography variant="subtitle1">
                      {projectDet?.creator.name}
                    </STypography>
                  </SBox5>

                  <SBox5>
                    <STypography variant="subtitle1">
                      <b>Project Lead</b>
                    </STypography>

                    <STypography variant="subtitle1">
                      {projectDet?.teamleads[0]?.name}
                    </STypography>
                  </SBox5>
                </SBox4>

                <STypography variant="subtitle1">
                  <b>Team Members</b>
                </STypography>

                <STypography paragraph>
                  <TagGroup>
                    {projectDet.members?.map((ele) => (
                      <SimpleTag text={ele.name} />
                    ))}
                  </TagGroup>
                </STypography>

                <STypography variant="subtitle1">
                  <b>Drive Link</b>
                </STypography>

                <STypography paragraph>
                  <a
                    href={`//${projectDet?.drive_link}`}
                    target="_blank"
                    rel="noopener"
                  >
                    {projectDet?.drive_link}
                  </a>
                </STypography>
              </>
            ) : (
              <form>
                <Box sx={{ fontFamily: "Roboto" }}>
                  <SBox6>
                    <Box sx={{ width: "48%" }}>
                      <STypography variant="subtitle1">
                        <b>
                          Project Name <sup style={{ color: "red" }}>*</sup>
                        </b>
                      </STypography>

                      <input
                        className="form-control"
                        type="text"
                        disabled={viewState}
                        value={projectDet.name}
                        onChange={(e) =>
                          setProjectDet({
                            ...projectDet,
                            name: e.target.value,
                          })
                        }
                      />
                    </Box>

                    <Box sx={{ width: "48%" }}>
                      <STypography variant="subtitle1">
                        <b>
                          End Date <sup style={{ color: "red" }}>*</sup>
                        </b>
                      </STypography>
                      <input
                        className="form-control datetimepicker"
                        type="date"
                        min={
                          moment().format("YYYY-MM-DD") >
                          projectDet.creation_date?.substr(0, 10)
                            ? moment().format("YYYY-MM-DD")
                            : projectDet.creation_date?.substr(0, 10)
                        }
                        value={enddate !== "" ? enddate : proend}
                        onChange={(e) => setEnddate(e.target.value)}
                      />
                    </Box>
                  </SBox6>

                  <STypography variant="subtitle1">
                    <b>
                      Description <sup style={{ color: "red" }}>*</sup>
                    </b>
                  </STypography>
                  <textarea
                    className="form-control"
                    type="text"
                    rows={1}
                    value={projectDet.description}
                    disabled={viewState}
                    onChange={(e) =>
                      setProjectDet({
                        ...projectDet,
                        description: e.target.value,
                      })
                    }
                  />

                  <STypography variant="subtitle1" sx={{ mt: 1.5 }}>
                    <b>
                      Project Leader <sup style={{ color: "red" }}>*</sup>
                    </b>
                  </STypography>
                  <Select
                    options={empList.map((ele) => ({
                      label: ele.label,
                      value: ele.value,
                      name: ele.label,
                      key: ele.value,
                    }))}
                    value={
                      projectDet?.teamleads
                        ? projectDet?.teamleads?.map((ele) => ({
                            label: ele.name,
                            value: ele.key,
                            name: ele.name,
                            key: ele.key,
                          }))
                        : []
                    }
                    onChange={(e) => {
                      if (e) {
                        setProjectDet({
                          ...projectDet,
                          teamleads: [e],
                        });
                      } else {
                        setProjectDet({
                          ...projectDet,
                          teamleads: [],
                        });
                      }
                    }}
                    // value={teamLeads}
                    // onChange={(e) => {
                    //   setTeamLeads(e ? e : teamLeads);
                    // }}
                  />
                  {/* <Select
                    // isOptionDisabled={viewState}
                    // isMulti
                    options={empList.map((ele) => ({
                      label: ele.label,
                      value: ele.value,
                      name: ele.label,
                      key: ele.value,
                    }))}
                    value={teamLead}
                    onChange={(e) => {
                      setTeamLead(e ? e : teamLead);
                    }}

                //     /*
                // onChange={(e) => {
                //   if (e) {
                //     {
                //       setProjectDet({
                //         ...projectDet,
                //         teamlead: e,
                //       }); console.log(e)
                //     };
                //   } else {
                //     setProjectDet({
                //       ...projectDet,
                //       teamlead: [],
                //     });
                //   }
                // }}
                // 
                  /> */}

                  <STypography variant="subtitle1" sx={{ mt: 1.5 }}>
                    <b>
                      Add Team Members <sup style={{ color: "red" }}>*</sup>
                    </b>
                  </STypography>
                  <Select
                    isMulti
                    options={empList.map((ele) => ({
                      label: ele.label,
                      value: ele.value,
                      name: ele.label,
                      key: ele.value,
                    }))}
                    value={projectDet.members?.map((ele) => ({
                      label: ele.name,
                      value: ele.key,
                      name: ele.name,
                      key: ele.key,
                    }))}
                    onChange={(e) => {
                      if (e) {
                        setProjectDet({
                          ...projectDet,
                          members: e.map((item) => item),
                        });
                      } else {
                        setProjectDet({
                          ...projectDet,
                          members: [],
                        });
                      }
                    }}
                  />

                  <STypography variant="subtitle1" sx={{ mt: 1.5 }}>
                    <b>Upload Drive Link</b>
                  </STypography>
                  <input
                    className="form-control"
                    type="text"
                    value={projectDet.drive_link}
                    onChange={(e) =>
                      setProjectDet({
                        ...projectDet,
                        drive_link: e.target.value,
                      })
                    }
                  />
                  <br />

                  <Box textAlign="center">
                    <SButton
                      onClick={(e) => handleProEdit(e)}
                      data-bs-dismiss={
                        !projectDet.name ||
                        !projectDet.description ||
                        projectDet.teamleads.length === 0 ||
                        projectDet.members.length === 0
                          ? //   ||
                            // !projectDet.deadline
                            ""
                          : "modal"
                      }
                    >
                      <STypography
                        variant="subtitle1"
                        sx={{ color: "#FFFFFF" }}
                      >
                        Save
                      </STypography>
                    </SButton>
                  </Box>
                </Box>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editproject;
